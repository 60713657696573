import { Button, Container, Divider, Grid, Box, Typography, CircularProgress, LinearProgress, IconButton, Menu, MenuItem  } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import './PagePrincipale.css'
import API from '../../services/API'
import ChoixUtilisateur from './components/ChoixUtilisateur';
import ListeTuilesRecettes from './components/ListeTuilesRecettes';
import ListeTuilesOperations from './components/ListeTuilesOperations';
import PopupProd from './components/Popups/PopupProd';
import PopupOperation from './components/Popups/PopupOperation';
import Dropdown from '../../components/Dropdown'
import Tile from '../../components/Tile';
import {SnackbarProvider} from 'notistack';
import { AccountCircle, Close } from '@mui/icons-material';
import { ThemeContext } from '../../App';
import { useNavigate  } from "react-router-dom";  
import { UserContext } from "../../services/UserContext"



export default function Accueil({loaded}){
    const [theme,setTheme] = useContext(ThemeContext)
    const [userContext, setUserContext] = useContext(UserContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const [groupes, setGroupes] = useState([]);
    const [recettes, setRecettes] = useState([{id:"0", nom:"init"}]);
    const [operations, setOperations] = useState([]);
    const [recettesOperations, setRecettesOperations] = useState([{id:"0", nom:"init"}]);
    const [utilisateurs, setUtilisateurs] = useState([{id:"0",nom:"----"}])
    const [recetteSelectionnee, selectionneRecette] = useState(0)
    const [operationSelectionnee, selectionneOperation] = useState(0)
    const [popupOpen, setPopupOpen] = useState(false)
    const [popup, setPopup] = useState(false)
    const [idUtilisateur, setIdUtilisateur] = useState("");
    
    //const history = useNavigate()
    const navigate = useNavigate();
    const routeChange = () =>{ 
        navigate("admin")
        /*let path = 'admin'; 
        history.push(path);*/
    }

    useEffect(()=>{
        if(!!userContext.prenom){
            setIdUtilisateur(userContext.id)
            return
        }
        API("GET","utilisateur/me",{},{},"application/json",{credentials:"include"}, userContext.token)
        .then(res=>{
            if (res.ok) {
                setUserContext(currVal => ({...currVal, ...res.json[0] }))
            } else {
                if (res.status === 401) {
                  // Edge case: when the token has expired.
                  // This could happen if the refreshToken calls have failed due to network error or
                  // User has had the tab open from previous day and tries to click on the Fetch button
                  window.location.reload()
                } /*else {
                  setUserContext(oldValues => {
                    return { ...oldValues, details: null }
                  })
                }*/
              }
        })
    },[userContext])

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout=()=>{
        API("GET","utilisateur/logout",{},{},"application/json",{credentials:"include"}, userContext.token)
        .then(res=>{
            console.log("logout",res)
            setUserContext(currVal => ({ ...currVal, token: null }))

        })
    }

    useEffect(() => {
        let promises = []
        promises.push(API("GET","recette").then(res=>res.json.map(r=>({...r,famille:"recette"}))))
        promises.push(API("GET","operation").then(res=>res.json.map(r=>({...r,famille:"operation"}))))

        Promise.all(promises).then(allRes=>{
            let tab = [...allRes[0],...allRes[1]]
            setGroupes([...new Set(tab.map(r=>r.groupe))])
            setRecettesOperations(tab)
        })
        
        API("GET","utilisateur")
        .then(res => setUtilisateurs(res.json), err => console.log(err))
    },[]);

    const choixRecette = (recetteId) => {
        selectionneRecette(recetteId); 
        setPopup("recette");
    }

    const choixOperation = (id) => {
        selectionneOperation(id); 
        setPopup("operation");
    }
    const choixRecetteOperation = (ro) => {
        if(ro.famille==="recette"){
            selectionneRecette(ro.id); 
            setPopup("recette");
        }else if(ro.famille==="operation"){
            selectionneOperation(ro.id); 
            setPopup("operation");   
        }
    }
    
    const chgtUtilisateur = (value) => {
        setIdUtilisateur(value);
    };

    const notistackRef = React.useRef();
    const onClickDismiss = key => () => { 
        notistackRef.current.closeSnackbar(key);
    }

    return(
        <SnackbarProvider ref={notistackRef} maxSnack={5} action={(key) => (<Button onClick={onClickDismiss(key)}><Close/></Button>)}>
            {!loaded && 
                <Box sx={{ display: 'flex', justifyContent:"center"}}>
                    <CircularProgress size={100} />
                </Box>
            }
            {loaded && 
                <Container /*maxWidth="md"*/ style={{color:theme.primary}}>
                    <Box sx={{/*display: "grid",gridAutoColumns: "1fr"*/display:"flex",justifyContent: 'space-between', pt:"20px" }}>
                        <Box sx={{ /*gridRow: "1", gridColumn: "1"*/ }}><Typography variant="h6">{new Date().toLocaleDateString()}</Typography></Box>
                        <Box sx={{ /*gridRow: "1", gridColumn: {xs:2,lg:3},*/width:{xs:"50%",md:"25%"} }}><img src={theme.logo} style={{width:"100%"}}/></Box>
                        <Box sx={{ /*gridRow: "1", gridColumn: {xs:3,lg:5},*/ textAlign: "right"}}><Button variant="contained" color="primary" onClick={routeChange}>Paramètres</Button></Box>
                    </Box>
                    <Box sx={{textAlign:"center"}}>
                        <Typography variant="h6">Bienvenue chez Abby !</Typography>
                        <Typography>Outil de gestion des stocks et de traçabilité</Typography>
                        {/*<Box display="grid" gridTemplateColumns='repeat(2, 1fr)' sx={{width:{md:"50%"}, marginX:"auto", marginTop:"20px "}}>
                            <Typography style={{height:"fitContent",margin:"auto"}}>Qui êtes-vous ?</Typography>
                            <Dropdown name="Utilisateur" defaultValue="" options={utilisateurs.filter(u=>u.actif!=0).map((u)=>({id:u.id,name:u.nom}))} required={true} onChange={chgtUtilisateur} style={{width:"fitContent", marginTop:"-16px"}}/>
                        </Box>*/}
                        <Box display="inline-flex">
                            <Typography alignSelf="center">Bonjour {userContext.prenom}</Typography>
                            <IconButton onClick={handleMenu}><AccountCircle/></IconButton>
                            <Menu anchorEl={anchorEl} anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} keepMounted
                                transformOrigin={{vertical: 'top',horizontal: 'center',}} open={Boolean(anchorEl)} onClose={handleClose}>
                                {/*<MenuItem onClick={handleClose}>Mon profil</MenuItem>*/}
                                <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                    <Box sx={{display: 'grid', columnGap: 3, gridTemplateColumns: {xs:'1fr',md:'repeat(2, 1fr)',lg:'repeat(4, 1fr)'} }}>
                        {groupes.map((g,kg) => (
                            <React.Fragment key={kg}>
                                <Box sx={{display: 'grid', gridTemplateRows: '1fr', m:2, alignSelf:"baseline"}}>
                                    <Typography variant="h6" style={{textAlign:"center"}}>{g?.toUpperCase()}</Typography>
                                    {recettesOperations.filter(r=>r.groupe==g).map(r=>(
                                        <Box key={r.famille+r.id} sx={{my:1,width:"100%"}} >
                                            <Button variant="contained" color="primary" style={{textAlign:"center",width:"100%"}} onClick={()=>choixRecetteOperation(r)} disabled={!idUtilisateur}>{r.nom}</Button>
                                        </Box>
                                    ))}
                                </Box>
                            </React.Fragment>
                        ))}
                    </Box>
                    {/*<Divider/>
                    <ListeTuilesOperations operations={operations} disabled={!idUtilisateur} onClickTile={choixOperation}/>*/}
                    <PopupProd idRecette={recetteSelectionnee} open={popup==="recette"} setOpen={setPopup} idUtilisateur={idUtilisateur}/>
                    <PopupOperation operationId={operationSelectionnee} open={popup==="operation"} setOpen={setPopup} idUtilisateur={idUtilisateur}/>
                </Container>
            }
        </SnackbarProvider>
    );
}
