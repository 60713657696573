import React, { useState, useEffect } from "react"
import {IconButton, Fab, Button} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import API from '../../../services/API'
import { Add, Edit, Delete } from '@mui/icons-material';
import PopupDB from './PopupDB';
import {useSnackbar} from 'notistack';

export default function Operations({visible}){
    const [operations, setOperations] = useState([])
    const [popupOpen, setPopupOpen] = useState(false)
    const [dataChange, setDataChange] = useState(true)
    const [actionPopup, setActionPopup] = useState("Créer")
    const [modifyingData, setModifyingData] = useState({})
    
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {field: 'actions', headerName: 'Actions', sortable: false,
            renderCell: (params) => {
              const onClickModify = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                handleModify(params.row)
              };
              const onClickDelete = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                let conf = window.confirm("Etes-vous sûr de vouloir supprimer cette production ? Pas de retour en arrière ...")
                if(conf){
                    handleDelete(params.row)
                }
              };
        
              return(
                  <React.Fragment>
                      <IconButton color="primary" component="span" onClick={onClickModify}><Edit /></IconButton>
                      <IconButton color="primary" component="span" onClick={onClickDelete}><Delete /></IconButton>
                  </React.Fragment>
              )
            },
        },
        { field: 'nom', title: 'Nom', flex:1},
        { field: 'type', title: 'Type', flex:1},
        { field: 'actif', title: 'Actif', flex:1},
        { field: 'NbActions', title: 'Nb Actions', flex:0.5},
    ];

    useEffect(()=>{
        if(!dataChange) return
        API("GET","operation/custom")
        .then(res => setOperations(res.json), err => console.log(err))
        setDataChange(false)
    },[dataChange])

    /*const actions=[{
        icon:()=><Edit/>,
        iconProps:{color:'primary'} ,
        tooltip:'Editer ligne',
        onClick:(event, rowData)=>handleModify(rowData)
    },{
        icon:()=><Delete/>,
        iconProps:{color:'primary'} ,
        tooltip:'Supprimer ligne',
        onClick:(event, rowData)=>handleDelete(rowData)
    }]*/

    const handleAdd = ()=>{
        setActionPopup("Créer")
        setModifyingData({})
        setPopupOpen(true)
    }

    const handleModify = (data)=>{
        setActionPopup("Modifier")
        setModifyingData(data)
        setPopupOpen(true)
    }

    const handleDelete = (data)=>{
        API("DELETE","Operation",{id:data.id})
        .then(res=>{
            if(!res.ok){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            setDataChange(true)
        })
    }


    return(
        (visible &&
            <React.Fragment>
                {/*<MaterialTable data={operations} columns={columns} options={{search: false, showTitle:false, paging:false}} actions={actions}/>*/}
                <div>
                    <DataGrid  
                        rows={operations} 
                        columns={columns} 
                        disableSelectionOnClick 
                        disableColumnFilter 
                        autoHeight
                    />
                </div>
                <Fab color="primary" aria-label="Add" className="fab" onClick={handleAdd}>
                    <Add />
                </Fab>
                <PopupDB action={actionPopup} data={modifyingData} table="Operation" extraData={[]} extraTable="ActionOperation" extraTableMultipleAnswers={true} open={popupOpen} setOpen={setPopupOpen} setDataChange={setDataChange} />
            </React.Fragment>
        )
    )
}