import React from 'react';
import { Grid, Box, Divider } from '@mui/material';
import BlocIngredientQuantite from './BlocIngredientQuantite'



export default function SaisieIngredients({ingredients, onChange, quantiteProduction}){
    if(ingredients.length > 0){
        return(
            <React.Fragment>
                <h3>Ingrédients</h3>
                <Box sx={{display: 'grid', gridAutoColumns: '1fr', fontWeight: 600, borderBottom:"1px solid lightgray", fontSize:{xs:"14px", md:"1rem"}}}>
                    <Box sx={{ gridRow: '1', gridColumn:"span 2", borderRight:"1px solid lightgray", textAlign:"center", padding:14}}>Ingrédients</Box>
                    <Box sx={{ gridRow: '1', gridColumn:"span 2", borderRight:"1px solid lightgray", textAlign:"center", padding:14}}>Quantité</Box>
                    <Box sx={{ gridRow: '1', gridColumn:"span 2", textAlign:"center", padding:14}}>Numéros de lot</Box>
                </Box>
                {ingredients.map((i)=>(
                    <BlocIngredientQuantite ingredient={i} onChange={onChange} quantiteProduction={quantiteProduction}/>
                ))}
                        
                {/*<table className="Table_Ingredients">
                    <thead>
                            <tr>
                                <th xs={3}>Ingrédients</th>
                                <th xs={4}>Numéros de lot</th>
                                <th xs={5}>Quantités</th>
                            </tr>
                    </thead>
                
                    <tbody>
                    {ingredients.map((i)=>(
                        <tr  key={i.id}>
                        <BlocIngredientQuantite ingredient={i} onChange={onChange} quantiteProduction={quantiteProduction}/>
                        </tr>
                    ))}
                    </tbody>
                    </table>*/}
            </React.Fragment>
        )
    }
    return null
}