import React, { useState, useEffect } from "react"
import Dropdown from "../../../components/Dropdown"
import { DataGrid } from '@mui/x-data-grid';
import API from '../../../services/API'
import { Fab, Button, IconButton } from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import PopupDB from './PopupDB';
import {useSnackbar} from 'notistack';


const types=[
    {id: "Matière première", name: "Matière première"},
    {id: "Produit intermédiaire", name: "Produit intermédiaire"},
    {id: "Produit fini", name: "Produit fini"}
]

/*const columns = [
    { field: 'id', hidden: true},
    { field: 'designation', title: 'Nom', width:300},
    { field: 'descriptionCourte', title: 'Description', width:300},
    { field: 'type', hidden: true },
    { field: 'uniteMesure', title: 'Unité de mesure'},
    { field: 'fournisseurPrefere', title: 'Fournisseur préféré'},
    { field: 'ddmParDefaut', title: 'DDM par défaut'},
    { field: 'idSellsy', title: 'ID Sellsy'},
    { field: 'codeBarre', title: 'Code-Barre'}
];*/

export default function Produits({visible}){
    const [produits, setProduits] = useState([])
    const [typeProduit, setTypeProduit] = useState("Matière première")
    const [dataDisplayed, setDataDisplayed] = useState([])
    const [dataChange, setDataChange] = useState(true)
    const [popupOpen, setPopupOpen] = useState(false)
    const [actionPopup, setActionPopup] = useState("Créer")
    const [modifyingData, setModifyingData] = useState({})
    const [filterModel, setFilterModel] = useState({items: [{ columnField: 'type', operatorValue: 'equals', value: 'Matière première' }]}); 
    
    const { enqueueSnackbar } = useSnackbar();    
    
    const columns = [
        {field: 'actions', headerName: 'Actions', sortable: false,
            renderCell: (params) => {
              const onClickModify = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                handleModify(params.row)
              };
              const onClickDelete = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                let conf = window.confirm("Etes-vous sûr de vouloir supprimer cette production ? Pas de retour en arrière ...")
                if(conf){
                    handleDelete(params.row)
                }
              };
        
              return(
                  <React.Fragment>
                      <IconButton color="primary" component="span" onClick={onClickModify}><Edit /></IconButton>
                      <IconButton color="primary" component="span" onClick={onClickDelete}><Delete /></IconButton>
                  </React.Fragment>
              )
            },
        },
        { field: 'id', hide: true},
        { field: 'designation', headerName: 'Nom', flex:1},
        { field: 'descriptionCourte', headerName: 'Description', flex:1},
        { field: 'type', hide: true },
        { field: 'uniteMesure', headerName: 'Unité de mesure', flex:1},
        { field: 'fournisseurPrefere', headerName: 'Fournisseur préféré', flex:1},
        { field: 'ddmParDefaut', headerName: 'DDM par défaut', flex:1},
        { field: 'idSellsy', headerName: 'ID Sellsy', flex:1},
        { field: 'idDeclSellsy', headerName: 'ID Déclinaison Sellsy', flex:1},
        { field: 'codeBarre', headerName: 'Code-Barre', flex:1}
    ];

    useEffect(()=>{
        if(!dataChange) return
        API("GET","produit","",{avecFabricablePar:1})
        .then(res => setProduits(res.json), err => console.log(err))
        setDataChange(false)
    },[dataChange])

    const handleProductTypeChange = React.useCallback( (value) => setFilterModel({items: [{ columnField: 'type', operatorValue: 'equals', value: value }]}) )

    /*useEffect(()=>{
        const tmp = produits.filter(p=>p.type===typeProduit)
        setDataDisplayed(tmp)
    },[produits, typeProduit])*/

    /*const actions=[{
        icon:()=><Edit/>,
        iconProps:{color:'primary'} ,
        tooltip:'Editer ligne',
        onClick:(event, rowData)=>handleModify(rowData)
    },{
        icon:()=><Delete/>,
        iconProps:{color:'primary'} ,
        tooltip:'Supprimer ligne',
        onClick:(event, rowData)=>handleDelete(rowData)
    }]*/

    const handleAdd = ()=>{
        setActionPopup("Créer")
        setModifyingData({})
        setPopupOpen(true)
    }

    const handleModify = (data)=>{
        setActionPopup("Modifier")
        setModifyingData(data)
        setPopupOpen(true)
    }

    const handleDelete = (data)=>{
        API("DELETE","Produit",{id:data.id})
        .then(res=>{
            if(!res.ok){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            setDataChange(true)
        })
    }


    return(
        (visible &&
            <React.Fragment>
                <Dropdown name="Type produit" defaultValue={typeProduit} options={types} onChange={handleProductTypeChange} />

                {/*<MaterialTable data={dataDisplayed} columns={columns} options={{search: false, showTitle:false, paging:false}} actions={actions}/>*/}
                <div>
                    <DataGrid  
                        rows={produits} 
                        columns={columns} 
                        filterModel={filterModel} 
                        disableSelectionOnClick 
                        disableColumnFilter 
                        autoHeight
                    />
                </div>
                <Fab color="primary" aria-label="Add" className="fab" onClick={handleAdd}>
                    <Add />
                </Fab>
                <PopupDB action={actionPopup} data={modifyingData} extraData={[]} table="Produit" extraTables={[]} open={popupOpen} setOpen={setPopupOpen} setDataChange={setDataChange} />
            </React.Fragment>
        )
    )
}