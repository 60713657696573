import React, { useState, useEffect } from "react"
import Dropdown from "../../../components/Dropdown"
import API from '../../../services/API'
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"


const types=[
    {id: "Matière première", name: "Matière première"},
    {id: "Produit intermédiaire", name: "Produit intermédiaire"},
    {id: "Produit fini", name: "Produit fini"}
]

export default function Stock({visible}){
    const [produits, setProduits] = useState([])
    const [productions, setProductions] = useState({})
    const [expandState, setExpandState] = useState({})
    const [typeProduit, setTypeProduit] = useState("Matière première")
    const [dataDisplayed, setDataDisplayed] = useState([])
    const [dataChange, setDataChange] = useState(true)

    const setStock = (params) => {
        console.log(params)
        let row = params.row
        let newValue = params.value
        let oldValue = row.stock
        API("POST","mouvementStock",{Produit_id:row.Produit_id,Production_id:row.id,quantite:newValue-oldValue, cause:"Inventaire"})
        .then(res=>{
            let tmp = dataDisplayed
            //let index = tmp.findIndex(obj=>obj.id==row.id)
            //tmp[index].stock = newValue
            let index = tmp.findIndex(obj=>!obj.hasOwnProperty("Produit_id") && obj.id==row.Produit_id)
            tmp[index].quantite=(Number(tmp[index].quantite)+Number(newValue)-Number(oldValue)).toFixed(4)
            setDataDisplayed(tmp)
        })
        return { ...params.row, stock:params.value };
    }

    const columns = [
        {field: 'expand', headerName: '', sortable: false,
            renderCell: (params) => {
                const onClickExpand = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    let id = params.row.id
                    if(expandState[id]===false){
                        setExpandState(currVal=>({...currVal,[id]:true}))
                        setDataDisplayed(currVal=>{
                            let index= currVal.findIndex(e=>e.id===id)
                            let tmp= [...currVal]
                            tmp.splice(index+1, 0, ...productions[typeProduit].filter(p=>p.Produit_id==id))
                            return tmp
                        })
                    }else{
                        setExpandState(currVal=>({...currVal,[id]:false}))
                        setDataDisplayed(currVal=>currVal.filter(p=>p.Produit_id!==id))
                    }
                };
        
              return(
                    !params.row.Produit_id && <IconButton color="primary" component="span" onClick={onClickExpand}>{expandState[params.row.id]?<ExpandLess/>:<ExpandMore/>}</IconButton>
              )
            },
        },

        { field: 'id', hide: true},
        { field: 'designation', headerName: 'Nom', flex:1},
        { field: 'date', headerName: 'Date', flex:1},
        { field: 'numeroLot', headerName: 'Numéro de lot', flex:1},
        { field: 'stock', headerName: 'Stock production', flex:1, editable:true, type:"number", valueSetter:setStock},
        { field: 'quantite', headerName: 'Stock total', flex:1, type:"number"}
    ];

    


    useEffect(()=>{
        if(!dataChange) return
        API("GET","produit",{},{withQuantity:1})
        .then(res => {
            if(!res.ok) return //erreur
            res=res.json
            res.map(r=>r.quantite*=1); 
            setProduits(res)
        }, err => console.log(err))
        setDataChange(false)
    },[dataChange])


    useEffect(()=>{
        let produitsFiltres = produits.filter(p=>p.type===typeProduit)

        setDataDisplayed(produitsFiltres)
        setExpandState(currVal=>{
            let toReturn = {}
            produitsFiltres.forEach(x=>{
                toReturn[x.id]=false
            })
            return toReturn
        })

        if(productions[typeProduit]?.length) return 

        if(!produitsFiltres.length){
            setProductions(currVal => ({...currVal, [typeProduit]:[]}))
            return
        }

        let queries = {specialsColumns:1}
        queries.IdProduit = produitsFiltres.map(p=>p.id)
        API("GET","production",{},queries)
        .then(res => {
            if(!res.ok) return //erreur
            res=res.json
            res.map(x=>x.stock=Number(x.stock))
            setProductions(currVal => ({...currVal, [typeProduit]:res}))
        })
    },[produits, typeProduit])

    return(
        (visible &&
            <React.Fragment>
                <Dropdown name="Type produit" defaultValue={typeProduit} options={types} onChange={(value)=>setTypeProduit(value)} />
                <div>
                    <DataGrid  
                        rows={dataDisplayed} 
                        columns={columns}  
                        disableSelectionOnClick 
                        disableColumnFilter 
                        autoHeight
                    />
                </div>
            </React.Fragment>
        )
    )
}