import React, { useEffect, useState } from 'react';
import { Add, Edit, Delete, Save } from '@mui/icons-material';
import {IconButton, Stack, Typography, Fab} from '@mui/material';
import API from '../../../services/API'
import LabelsPicker from '../../../components/LabelsPicker';
import {useSnackbar} from 'notistack';
import { ColorPicker, createColor } from "material-ui-color";
import Dropdown from '../../../components/Dropdown';
import DragAndDropFile from '../../../components/DragAndDropFile';
import Input from '../../../components/Input';


export default function Parametres({visible}){
    const [popupOpen, setPopupOpen] = useState(false)
    const [utilisateurs, setUtilisateurs] = useState([])
    const [dataChange, setDataChange] = useState(true)
    const [actionPopup, setActionPopup] = useState("Créer")
    const [modifyingData, setModifyingData] = useState({})
    const [typeMvts, setTypeMvts] =useState([])
    const [repTypesMvts, setRepTyesMvts] = useState([])
    const [params, setParams] = useState([])
    const [repParams, setRepParams] = useState([])
    const [colors, setColors] = useState({});
    
    const { enqueueSnackbar } = useSnackbar();


    useEffect(()=>{
        if(!dataChange) return
        setRepParams([])
        API("GET","mouvementStock/DESCRIBE")
        .then(res=>{
            let exp = /^enum\(\'(.*)\'\)$/
            setTypeMvts(exp.exec(res.json.find(x=>x.Field=="cause").Type)[1].split("','").map((v,k)=>({id:k+1,Nom:v})))
        })
        API("GET","parametre")
        .then(res=>{
            if(!res.ok) return //erreur
            res=res.json
            res.map(a => {
                a.source=JSON.parse(a.source)
                if(a.type == "Couleur"){
                    setColors(currVal => ({...currVal, [a.id]:createColor(a.valeur)}))
                }
            });
            setParams(res)
        })
        setDataChange(false)
    },[dataChange])


    const handleTypeMvtsChange = (types)=>{
        setRepTyesMvts(types)
    }

    const handleParamsChange = (id,value)=>{
        //let i = params.findIndex(p=>p.id==id)
        let paramChanged = params.find(p=>p.id==id)
        switch(paramChanged.type){
            case "Couleur":
                let tmp
                if(typeof value === 'object' && !Array.isArray(value) && value !== null){
                    tmp=value
                }else if(typeof value === 'string'){
                    tmp = createColor(value)
                }else{
                    tmp = undefined
                }   
                setColors(currVal=>({...currVal,[id]:tmp}))
                paramChanged.valeur=tmp.css.backgroundColor
                break
            case "Image":
                paramChanged.valeur=value[0]
            default:
                paramChanged.valeur=value
        }
        let tmp = repParams
        let find = tmp.find(x=>x.id==id)
        if(find){
            find.valeur=paramChanged.valeur
        }else{
            tmp.push({id:id,type:paramChanged.type,valeur:paramChanged.valeur})
        }
        setRepParams(tmp)
    }

    useEffect(()=>{
        console.log("repParams",repParams)
    },[repParams])

    const handleSave = ()=>{
        if(checkIfChangment(typeMvts, repTypesMvts)){
            API("PUT","mouvementStock/structure/cause",{cause:repTypesMvts.map(x=>x.Nom)})
            .then(res=>{
                if(!res.ok){//ERREUR
                    enqueueSnackbar('Erreur lors de la modification de la liste "cause" des mouvements de stock',{variant: "error"})
                }else{
                    enqueueSnackbar('Modification de la liste "cause" des mouvements de stock réussie',{variant: "success"})
                }
                setDataChange(true)
            })
        }

        /*var data = new FormData()
        repParams.forEach(p=>{
            if(p.type=="Image"){
                data.append("image",p.valeur)
                delete p.valeur
            }
            data.append(p.id,JSON.stringify(p))
        })*/
        
        API("PUT","parametre/valeur",repParams/*[0]data,{key:"ki99=9!2?^8|DVUWzwzX"},"multipart/form-data"*/)
        .then(res=>{
            if(!res.ok){//ERREUR
                enqueueSnackbar('Erreur lors de la sauvegarde des paramètres',{variant: "error"})
            }else{
                enqueueSnackbar('Sauvegarde des paramètres réussie',{variant: "success"})
            }
            setDataChange(true)
        })
    }


    return(
        (visible &&
            <React.Fragment>
                <Stack  direction="row" spacing={1} alignItems="center">
                    <Typography>Type de mouvement de stock</Typography>
                    <LabelsPicker initialLabels={typeMvts} initialSelectedLabels={typeMvts} allowCreation={true} onChange={handleTypeMvtsChange} />
                </Stack>
                {params.map(p=>
                    <Stack key={p.id} direction="row" spacing={1} alignItems="center">
                        <Typography>{p.nom}</Typography>
                        {paramSelect[p.type](p, (val)=>handleParamsChange(p.id, val), colors[p.id])}
                        {/*<ParamSelect {...p} color={colors[p.id]} onChange={(val)=>handleParamsChange(p.id, val)} />*/}
                    </Stack>
                )}

                <Fab color="primary" aria-label="Add" className="fab" onClick={handleSave}>
                    <Save />
                </Fab>
        {/*<PopupDB action={actionPopup} data={modifyingData} extraData={[]}  table="utilisateur" open={popupOpen} setOpen={setPopupOpen} setDataChange={setDataChange}/>*/}
            </React.Fragment>
        )
    )
}

const paramSelect = {
    "Liste déroulante": (param, onChange)=><Dropdown name="" onChange={onChange} defaultValue={param.valeur} options={param.source.values.map((x,i)=>({id:i,name:x}))}/>,
    "Couleur":          (param, onChange, color)=><ColorPicker value={color} onChange={onChange}/>,
    //"Image":            (param, onChange)=><DragAndDropFile typeFichier="image/*" multiple={false} onChange={onChange} />
    "Image":            (param, onChange)=><img src={param.valeur} width={200}/>,
    "String":           (param, onChange)=><Input name="" defaultValue={param.valeur} onChange={onChange}/>
}

function checkIfChangment(initial, reponse){
    if(reponse.filter(x=>!x.hasOwnProperty("id")).length) return true
    if(initial.length != reponse.length) return true
    for(let i of initial){
        let y = reponse.find(x=>x.id==i.id)
        if(y===undefined) return true
        if(y.Nom != i.Nom) return true
    }
    return false
}
function checkIfParamsChanges(initial, reponse){
    for(let i of initial){
        let y = reponse.find(x=>x.id==i.id)
        if(i.valeur !== y.valeur) return true
    }
    return false
}
