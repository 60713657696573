import React, { useEffect, useState } from 'react'
import TypeListeDeroulante from './components/TypeListeDeroulante'
import TypeDate from './components/TypeDate'
import TypeInput from './components/TypeInput'
import TypeNombre from './components/TypeNombre'
import TypeChecklist from './components/TypeChecklist'
import TypeCheckbox from './components/TypeCheckbox'
import TypeCaptureNL from './components/TypeCaptureNL'
import API from '../../../../services/API'
import { Container } from '@mui/material'

const actions = {
    "Liste déroulante": (action, onChange, reloadOn)=><TypeListeDeroulante key={action.id} action={action} onChange={onChange} produit={reloadOn}/>,
    "Date":             (action, onChange, reloadOn)=><TypeDate key={action.id} action={action} onChange={onChange} produit={reloadOn}/>,
    "Réponse courte":   (action, onChange, reloadOn)=><TypeInput key={action.id} action={action} onChange={onChange} produit={reloadOn}/>,
    "Nombre":           (action, onChange, reloadOn)=><TypeNombre key={action.id} action={action} onChange={onChange} produit={reloadOn}/>,
    "Checklist":        (action, onChange)=><TypeChecklist key={action.id} action={action} onChange={onChange}/>,
    "Checkbox":         (action, onChange)=><TypeCheckbox key={action.id} action={action} onChange={onChange}/>,
    "Capture Num Lot":  (action, onChange)=><TypeCaptureNL key={action.id} action={action} onChange={onChange}/>,
}

export default function ActionsOperation({reloadOn, operationId, onChange}){
    const [actionsOperation, setActionsOperation] = useState([]);

    useEffect(() => {
        if(!operationId) return

        API('GET', 'actionOperation', {}, {Operation_id:operationId})
        .then(res => {
            if(!res.ok) return //erreur
            res=res.json
            if(res.length === 0) return
            res.map(a => a.source= JSON.parse(a.source))
            setActionsOperation(res);
        }, err => console.log(err))
    },[operationId]);

    return(
        (actionsOperation.length > 0 && 
        <React.Fragment>
            <h3>Autres informations</h3>
            <Container>
                {actionsOperation.map(a=>(actions[a.typeQuestion](a, onChange, reloadOn)))}
            </Container>
        </React.Fragment>)
        
    )
}

