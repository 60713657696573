import React, { useEffect, useState } from 'react';
import {Button, Container, Grid, IconButton, Divider, Box} from '@mui/material';
import API from '../../../services/API'
import {SellsyAPI, SellsyCreateDocument, SellsyDocumentUpdateRows, SellsyDocumentUpdateStep, SellsyDocumentUpdateDeliveryStep} from '../../../services/SellsyAPI'
import Number from '../../../components/Number'
import SearchableDropdown from '../../../components/SearchableDropdown'
//import { Page, Text, View, Document, BlobProvider } from '@react-pdf/renderer';
import Dropdown from '../../../components/Dropdown';
import { AddCircle } from '@mui/icons-material';
import { green } from '@mui/material/colors';

/*const MyDocument = () => (
  <Document>
    <Page size="A4" style={{ backgroundColor: 'tomato' }}>
      <View style={{ color: 'white', textAlign: 'center', margin: 30 }}>
        <Text>Section #1</Text>
      </View>
    </Page>
  </Document>
);*/


export default function SupplementEnvoiCde({operation,setSupplementFormValid,submiting}){
    const [commandesAEnvoyer, setCommandesAEnvoyer] = useState([])
    const [commandeSelectionnee, setCommandeSelectionnee] = useState()
    const [produitsCommande, setProduitsCommande] = useState([])
    const [reponsesSupplement, setReponsesSupplement] = useState({numLot:[], nbreColis:1})
    const [isFullySent, setIsFullySent] = useState({numLot:[]})
    const [loadingCommandes, setLoadingCommandes] = useState(false) 
    const [sellsyCde, setSellsyCde] = useState(undefined)
    
    useEffect(()=>{
        setLoadingCommandes(true)
        let queryDocGetList = {
            'doctype':  "order",
            'pagination':{
                'nbperpage': 100
            }
        }
        if(process.env.NODE_ENV==="production"){
            queryDocGetList.search = {'savedSearchId': 29126}
        }else if (process.env.NODE_ENV==="development"){
            queryDocGetList.search = {'steps':    ["read","accepted"]}
        }
        SellsyAPI('Document.getList',queryDocGetList)
        .then(data => {
            console.log('Commandes à envoyer', data.response);
            setCommandesAEnvoyer(Object.values(data.response.result).map(e=>({id:e.id, ident:e.ident, thirdname:e.thirdname, step_label:e.step_label})))
            setLoadingCommandes(false)
        }).catch(e => {
            console.log('error:', e);
        });
    },[""])

    useEffect(()=>{
        if(!reponsesSupplement.commandeSelectionnee) return 

        setProduitsCommande([])
        setReponsesSupplement(currVal=>({...currVal,numLot:[],colisage:[]})) // (ré)-init

        SellsyAPI('Document.getOne', {doctype:"order", docid:reponsesSupplement.commandeSelectionnee})
        .then(async data => {
            setSellsyCde(data.response)
            /*Object.values(data.response.map.rows).forEach(r=>{
                if(typeof r === 'object' && r !== null && r.hasOwnProperty("qt")){
                    r["qtRestante"] = r.qt*1
                }
            })

            let linkedDocs = await SellsyAPI("Document.getLinkedDocuments", { doctype:  "order", docid: data.response.id})
            let directChildren = linkedDocs.response.directChildren
            if(Object.keys(directChildren).length){
                let promises = []
                directChildren = Object.keys(directChildren).filter(x=>x.startsWith("delivery")).map(x=>x.substring(9))
                directChildren.forEach(dc=>{
                    promises.push(SellsyAPI('Document.getOne', {doctype:"delivery", docid:dc}).then(x=>x.response))
                })
                await Promise.all(promises).then(deliveries=>{
                    deliveries.forEach(delivery=>{
                        Object.values(delivery.map.rows).filter(x=>x.type=="item").forEach(rowDelivery=>{
                            data.response.map.rows[rowDelivery.parentid].qtRestante = data.response.map.rows[rowDelivery.parentid].qtRestante - rowDelivery.qt
                            setSellsyCde(data.response)
                        })
                    })
                })
            }*/

            console.log('Commande #'+reponsesSupplement.commandeSelectionnee, data.response.map);
            let sellsyProduits = []
            Object.values(data.response.map.rows).forEach(e=>{
                if(!e.hasOwnProperty("id")) return
                if(e.type!=="item") return
                sellsyProduits.push({idSellsy:e.linkedid, idDeclSellsy:e.declid, quantite:e.qt*1, idLigne:e.id})
            })

            API("GET","Produit",{},{idsSellsy:sellsyProduits.map(e=>e.idSellsy)})
            .then(res=>{
                if(!res.ok) return //erreur
                res=res.json
                sellsyProduits.forEach((s,i)=>{
                    let possible = undefined
                    let found = false
                    for(let a of res){
                        if(s.idSellsy==a.idSellsy){
                            if((s.idDeclSellsy==a.idDeclSellsy) || (s.idDeclSellsy==0 && a.idDeclSellsy == null)){
                                sellsyProduits[i] = {...s,...a}
                                found = true
                                break
                            }else if(a.idDeclSellsy == null){
                                possible = a
                            }
                        }
                    }
                    if(!found){
                        if(possible !== undefined){
                            sellsyProduits[i] = {...s,...possible}
                        }else{
                            sellsyProduits[i] = {...s, notFound:true, descriptionCourte:"Impossible de trouver la correspodance pour le produit Sellsy "+s.idSellsy+" - "+s.idDeclSellsy, textColor:"red"}
                        }
                    }
                })
                setProduitsCommande(sellsyProduits)
            })
          }).catch(e => {
            console.log('error:', e);
          });
    },[reponsesSupplement.commandeSelectionnee])

    useEffect(()=>{
        console.log("Produits commande",produitsCommande)
        if(!produitsCommande) return

        let colisageTemp ={}
        let numLotTemp = {}
        produitsCommande.forEach(p=>{

        })
    },[produitsCommande])

    const handleChange = (question,reponse)=>{
        if(typeof reponse === 'object' && reponse !== null){
            setReponsesSupplement(currValue=>({...currValue,[question]:{...currValue[question],...reponse}}))
        }else{
            setReponsesSupplement(currValue=>({...currValue,[question]:reponse}))
        }
    }

    useEffect(()=>{  
        console.log("Réponses supp",reponsesSupplement)
        if(!reponsesSupplement.commandeSelectionnee || !Object.keys(reponsesSupplement.numLot).length || !Object.keys(reponsesSupplement.colisage).length) {
            setSupplementFormValid(false)
            return
        }
        /*if(Object.values(reponsesSupplement.numLot).reduce((x,y)=>x||(y===undefined),false)){
            setSupplementFormValid(false)
            return
        }*/
        let isValid = true
        let quantitesCommande = 0
        let tmpIsFullySent = true
        produitsCommande.forEach(p=>{
            if(p.quantite===0) return
            let quantiteEnvoyee = reponsesSupplement.colisage[p.idLigne].map(x=>x.quantite).reduce((x,y)=>x+y,0)
            quantitesCommande += quantiteEnvoyee
            if(quantiteEnvoyee > p.quantite || (quantiteEnvoyee > 0 && p.hasOwnProperty("notFound"))){
                isValid = false
                return
            }else if(quantiteEnvoyee < p.quantite){
                tmpIsFullySent = false
            }
            if(reponsesSupplement.numLot[p.idLigne] === undefined && quantiteEnvoyee !==0){
                isValid = false
                return
            }
        })
        if(quantitesCommande==0){
            isValid = false
        }
        setIsFullySent(tmpIsFullySent)
        setSupplementFormValid(isValid)
    },[reponsesSupplement.numLot,reponsesSupplement.colisage])


    useEffect(()=>{
        if(!sellsyCde) return 
        let step = "sent"
        let infoCom = Object.values(sellsyCde.customfieldsGroups).find(x=>x.name === "Info commerciales")?.list
        if(infoCom){
            step = Object.values(infoCom).find(x=>x.name === "Agent commercial")?.formatted_value === "Site internet" ? "invoiced" : "sent"
        }
        console.log("step",step)
    },[sellsyCde])

    useEffect(()=>{
        if(!submiting) return 
        let mvtsStock = []
        let deliveryRows = {}
        let reliquatRows = produitsCommande.reduce((a,v)=>({...a, [v.idLigne]:v.quantite}),{})
        Object.entries(reponsesSupplement.numLot).forEach(([k,v])=>{//forEach((k,v)=>{
            if(v !== undefined){
                if(!Array.isArray(v)) v=[v]
                let qtTotalEnvoi =  v.map(x=>x.quantite).reduce((x,y)=>x+y,0)
                v.forEach(prod=>{
                    mvtsStock.push({Produit_id:produitsCommande.find(p=>p.idLigne==k).id,Production_id:prod.reference_id,cause:"Livraison",quantite:-1*prod.quantite,ref_LogOperation_id:submiting})
                })
                deliveryRows[k] = qtTotalEnvoi
                reliquatRows[k] = reliquatRows[k] - qtTotalEnvoi
                if(reliquatRows[k]==0) delete reliquatRows[k]
            }
        }) 
        API("POST","mouvementStock",mvtsStock)
        .then(res=>{
            if(!res.ok){  //ERREUR
                //enqueueSnackbar("Erreur lors de la création dans la table mouvementstock",{variant: "error"})
                return
            }
        })
        let promises = []
        if(Object.keys(reliquatRows).length){
            SellsyCreateDocument("order", sellsyCde, reliquatRows, false, "accepted")
            promises.push(SellsyDocumentUpdateRows(sellsyCde, deliveryRows, true))
        }
        Promise.all(promises).then(allRes=>{ 
            console.log("rep update doc promise",allRes[0])
            let step = "sent"
            let infoCom = Object.values(sellsyCde.customfieldsGroups).find(x=>x.name === "Info commerciales")?.list
            if(infoCom){
                step = Object.values(infoCom).find(x=>x.name === "Agent commercial")?.formatted_value === "Site internet" ? "invoiced" : "sent"
            }
            SellsyCreateDocument("delivery", sellsyCde, deliveryRows, true, step, "wait", reponsesSupplement.nbreColis) 
        })
        SellsyDocumentUpdateDeliveryStep(sellsyCde.docid, "sent")  

        
    },[submiting])

      return(
        <Container maxWidth='md'>
            <Box sx={{width:{xs:"100%", md:"50%"}, margin:"auto"}}>
                <SearchableDropdown name="Commande à expédier" required={true} options={commandesAEnvoyer.map(e=>({id:e.id, name:e.ident+" - "+e.thirdname+" ("+e.step_label+")"}))} loading={loadingCommandes} onChange={(v)=>handleChange("commandeSelectionnee",v)} style={{ width:'100%%'}}/>
            </Box> 
            <Dropdown name="Nombre de colis" defaultValue={1} required={true} onChange={v=>handleChange("nbreColis",v)} options={[{id:1,name:1},{id:2,name:2},{id:3,name:3},{id:4,name:4},{id:5,name:5},{id:6,name:6},{id:7,name:7},{id:8,name:8},{id:9,name:9},{id:10,name:10}]}  style={{ width:'50%', minWidth:500, margin:"auto", display:"flex" }}/>
            <h3>Produits</h3>
            <Box sx={{display: 'grid', gridAutoColumns: '1fr', fontSize:{xs:"14px", md:"1rem"}}}>
                <Box sx={{ gridRow: '1', gridColumn:"span 2", borderRight:"1px solid lightgray", textAlign:"center"}}>Produit</Box>
                <Box sx={{ gridRow: '1', borderRight:"1px solid lightgray", textAlign:"center", fontSize:{xs:"10px", md:"inherit"}}}>Quantité à expédier</Box>
                <Box sx={{ gridRow: '1', gridColumn:"span 4", borderRight:{xs:"0", md:"1px solid lightgray"}, textAlign:"center"}}>Colisage</Box>
                <Box sx={{ gridRow: '1', gridColumn:"span 3", display: { xs: 'none', md:'block'}, textAlign:"center"}}>Numéro de lot</Box>
                
            </Box>
            {/*<Grid container alignItems="center" spacing={0} width="md">
                <Grid item xs={4} style={{textAlign:"center"}}>Produit</Grid>
                <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                <Grid item xs={1} style={{textAlign:"center"}}>Quantité à expédier</Grid>
                <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                <Grid item xs={4} style={{textAlign:"center"}}>Colisage</Grid>
                <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                <Grid item xs={3} style={{textAlign:"center"}}>Numéro de lot</Grid>
            </Grid>*/}
            {produitsCommande.map((p,k)=>(
                <BlocProduitQuantite key={reponsesSupplement.commandeSelectionnee*100+k} produit={p} nbreColis={reponsesSupplement.nbreColis} onChangeColisage={(v)=>handleChange("colisage",{[p.idLigne]:v})} onChangeNumLot={(v)=>handleChange("numLot",{[p.idLigne]:v})}/>
            ))}
        </Container>
      )
}


function BlocProduitQuantite({produit, nbreColis, onChangeColisage, onChangeNumLot}){
    const [options0, setOptions0] = useState([])
    const [options1, setOptions1] = useState([])
    const [options2, setOptions2] = useState([])
    const [selectedOption, selectOption] = useState([{}])
    const [quantiteNecessaire, setQuantiteNecessaire] = useState(0)
    const [quantiteLigne, setQuantiteLigne] = useState(produit.quantite)
    const [defValNumLot, setDefValNumLot] = useState([])
    const [helperTexts, setHelperTexts] = useState({0:{text:"",type:"default"},1:{text:"",type:"default"},2:{text:"",type:"default"}})
    const [optionsNumColis, setOptionsNumColis] = useState([{id:1,name:1}])
    const [colisage, setColisage] = useState([{colis:1, quantite:produit.quantite}])

    useEffect(()=>{
        if(!nbreColis) return
        setOptionsNumColis([...Array(nbreColis).keys()].map((v,k)=>({id:k+1,name:k+1})))
    },[nbreColis])


    useEffect(()=>{
        if(produit===undefined) return
        setDefValNumLot([])
        API("GET","production", {}, {IdProduit:produit.id, onlyPositiveStock:true})
        .then(res => {
            if(!res.ok) return //erreur
            res=res.json
            let d = []
            res.map(x => {
                let tmp = new Date(x.date.substring(0,10))
                tmp = tmp.toLocaleDateString('fr-FR').replace(/\//gi,"-")
                let numLotStr = x.numeroLot===null ? "" : "NumLot#"+x.numeroLot+" "
                d.push({id:x.id, name:numLotStr+"ID#".concat(x.id,"  du ",tmp,"  ",x.stock," restants"), quantiteMax:x.stock*1})
            })
            setOptions0(d)
            if(d.length===1){
                setDefValNumLot([d[0].id])
                selectOption([{id:d[0].id,quantiteMax:d.filter(x=>x.id==d[0].id)[0].quantiteMax}])
            }else if(d.length===0){
                setHelperTexts(c=>({...c, 0:{text:"Produit indisponible",type:"error"}}))
                onChangeNumLot(undefined)
            }
        })
    }, [produit])  

    const handleChangeQuantite = (value) =>{
        setQuantiteNecessaire(value)
    }

    const handleChangeLot = (index, value) =>{
        selectOption(currValue=>({...currValue,[index]:{id:value,quantiteMax:options0.filter(x=>x.id==value)[0].quantiteMax}}))
    }
    useEffect(()=>{
        if(Array.isArray(options0) && selectedOption[0]){
            const tmp = options0.filter(o=>o.id!=selectedOption[0].id)
            setOptions1(tmp)
        }
        if(Array.isArray(options1) && selectedOption[1])
            setOptions2(options1.filter(o=>o.id!=selectedOption[1].id))
    },[selectedOption])

    useEffect(()=>{
        verifierQuantites();
    },[quantiteLigne,selectedOption])

    function verifierQuantites(){
        if(isNaN(quantiteLigne) || quantiteLigne <= 0 || Object.keys(selectedOption[0]).length===0){
            onChangeNumLot(undefined)
            return
        }
        switch(options0.length){
            case 0:
                break
            case 1:
                if(selectedOption[0].quantiteMax<quantiteLigne){
                    setHelperTexts(c=>({...c, 0:{text:"Quantité en stock insuffisante",type:"error"}}))
                    onChangeNumLot(undefined)
                }else{
                    setHelperTexts(c=>({...c, 0:{text:"",type:"default"}}))
                    onChangeNumLot({reference_id:selectedOption[0].id, quantite:quantiteLigne})
                }
                break
            case 2:
                if(selectedOption[0].quantiteMax>=quantiteLigne){
                    setHelperTexts(c=>({...c, 0:{text:"",type:"default"}}))
                    onChangeNumLot({reference_id:selectedOption[0].id, quantite:quantiteLigne})
                }else if(selectedOption[1] == undefined){
                    onChangeNumLot(undefined)
                }else if(selectedOption[0].quantiteMax+selectedOption[1].quantiteMax<quantiteLigne){
                        setHelperTexts(c=>({...c, 1:{text:"Quantité en stock insuffisante",type:"error"}}))
                        onChangeNumLot(undefined)
                }else{
                    setHelperTexts(c=>({...c, 1:{text:"",type:"default"}}))
                    onChangeNumLot([{reference_id:selectedOption[0].id, quantite:selectedOption[0].quantiteMax}, {reference_id:selectedOption[1].id, quantite:quantiteLigne-selectedOption[0].quantiteMax}])
                }
                break
            default:
                if(selectedOption[0].quantiteMax>=quantiteLigne){
                    setHelperTexts(c=>({...c, 0:{text:"",type:"default"}}))
                    onChangeNumLot({reference_id:selectedOption[0].id, quantite: quantiteLigne})
                }else if(selectedOption[1] == undefined){
                    onChangeNumLot(undefined)
                }else if(selectedOption[0].quantiteMax+selectedOption[1].quantiteMax>=quantiteLigne){
                    setHelperTexts(c=>({...c, 0:{text:"",type:"default"}}))
                    onChangeNumLot([{reference_id:selectedOption[0].id, quantite:selectedOption[0].quantiteMax}, {reference_id:selectedOption[1].id, quantite:quantiteLigne-selectedOption[0].quantiteMax}])
                }else if(selectedOption[2] == undefined){
                    onChangeNumLot(undefined)
                }else if(selectedOption[0].quantiteMax+selectedOption[1].quantiteMax+selectedOption[2].quantiteMax<quantiteLigne){
                    setHelperTexts(c=>({...c, 1:{text:"Quantité en stock insuffisante",type:"error"}}))
                    onChangeNumLot(undefined)
                }else{
                    setHelperTexts(c=>({...c, 1:{text:"",type:"default"}}))
                    onChangeNumLot([{reference_id:selectedOption[0].id, quantite:selectedOption[0].quantiteMax}, {reference_id:selectedOption[1].id, quantite:selectedOption[1].quantiteMax}, {reference_id:selectedOption[2].id, quantite:quantiteLigne-selectedOption[0].quantiteMax-selectedOption[1].quantiteMax}])
                }
        }
    }
    const handleChangeColisage = (key, type, value)=>{
        setColisage(currVal=>{
            currVal[key] = {...currVal[key],[type]:value}
            return [...currVal]
        })
    }

    useEffect(()=>{
        setQuantiteLigne(colisage.map(c=>c.quantite).reduce((a,b)=>a+b,0))
        onChangeColisage(colisage)
    },[colisage])



    return(
        <React.Fragment>
            <Box sx={{display: 'grid', gridAutoColumns: '1fr', borderTop:"1px solid lightgray"}}>
                <Box sx={{ gridRow: {xs:"1/3", md:"1"}, gridColumn:"span 2", borderRight:"1px solid lightgray", textAlign:"center", padding:"8px"}}>{produit.descriptionCourte}</Box>
                <Box sx={{ gridRow: {xs:"1/3", md:"1"}, borderRight:"1px solid lightgray", textAlign:"center", padding:"8px"}}>{produit.quantite}</Box>
                <Box sx={{ gridRow: "1", gridColumn:"span 4", borderRight:{xs:"0", md:"1px solid lightgray"}, textAlign:"-webkit-center", padding:"8px"}}>
                    {colisage.map((x,k)=>(
                        <Box key={k} sx={{display: 'grid', gridTemplateColumns:'repeat(2, 1fr)', gridColumn:"span 7"}}>
                            <Box sx={{display:{xs:"none",md:"block"}}}><Number name="Quantité" defaultValue={x.quantite} ornementFin={produit.uniteMesure}  decimalScale={4} onChange={v=>handleChangeColisage(k,"quantite",v)} allowNegative={false} maximum={produit.quantite}/></Box>
                            <Box sx={{display:{xs:"block",md:"none"}}}><Number name="Quantité" defaultValue={x.quantite} ornementFin={produit.uniteMesure}  decimalScale={4} onChange={v=>handleChangeColisage(k,"quantite",v)} allowNegative={false} maximum={produit.quantite} style={{fontSize:"14px", paddingAdornment:"2px"}}/></Box>
                            <Box><Dropdown name="Colis #" defaultValue={x.colis} options={optionsNumColis} onChange={v=>handleChangeColisage(k,"colis",v)} style={{width:"100%"}}/></Box>
                        </Box>
                    ))}
                    {nbreColis > colisage.length && 
                        <Box><IconButton onClick={()=>setColisage(currValue=>([...currValue, {colis:1, quantite:0}]))} style={{ color: green[500] }}><AddCircle/></IconButton></Box>
                    }
                </Box>
                <Box sx={{ gridRow: {xs:"2", md:"1"}, gridColumn:{xs:"span 4", md:"span 3"}, textAlign:"center", padding:"8px"}}>
                    <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options0} helperText={helperTexts[0]} onChange={(value)=>handleChangeLot(0,value)}/>
                    {(selectedOption[0] && selectedOption[0].quantiteMax<quantiteLigne && options1.length > 0 &&
                        <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options1} helperText={helperTexts[1]} onChange={(value)=>handleChangeLot(1,value)}/>
                    )}
                    {(selectedOption[0] && selectedOption[1] && selectedOption[0].quantiteMax + selectedOption[1].quantiteMax <quantiteLigne && options2.length > 0 &&
                        <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options2} helperText={helperTexts[2]} onChange={(value)=>handleChangeLot(2,value)}/>
                    )}
                </Box>
            </Box>
            {/*<Divider/>
            <Grid container alignItems="center" spacing={0} width="md">
                <Grid item xs={4} style={{textAlign:"center", color:produit.textColor||"black"}}>
                    {produit.descriptionCourte}
                </Grid>
                <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                <Grid item xs={1} border={1} style={{textAlign:"center"}}>
                    {produit.quantite}
                </Grid>
                <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                <Grid item xs={4} container spacing={2} alignItems="center" style={{textAlign:"center", margin:0}}>
                    {colisage.map((x,k)=>(
                        <Grid key={k} item container spacing={2} xs={11}>
                            <Grid item xs={6}><Number name="Quantité" defaultValue={x.quantite} ornementFin={produit.uniteMesure}  decimalScale={4} onChange={v=>handleChangeColisage(k,"quantite",v)} allowNegative={false} maximum={produit.quantite} /></Grid>
                            <Grid item xs={6}><Dropdown name="Colis #" defaultValue={x.colis} options={optionsNumColis} onChange={v=>handleChangeColisage(k,"colis",v)} style={{width:"100%"}}/> </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={1}>
                        {nbreColis > colisage.length && 
                            <IconButton onClick={()=>setColisage(currValue=>([...currValue, {colis:1, quantite:0}]))} style={{ color: green[500] }}><AddCircle/></IconButton>
                        }
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                <Grid item xs={3} style={{textAlign:"center"}}>
                    <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options0} helperText={helperTexts[0]} onChange={(value)=>handleChangeLot(0,value)}/>
                    {(selectedOption[0] && selectedOption[0].quantiteMax<quantiteLigne && options1.length > 0 &&
                        <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options1} helperText={helperTexts[1]} onChange={(value)=>handleChangeLot(1,value)}/>
                    )}
                    {(selectedOption[0] && selectedOption[1] && selectedOption[0].quantiteMax + selectedOption[1].quantiteMax <quantiteLigne && options2.length > 0 &&
                        <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options2} helperText={helperTexts[2]} onChange={(value)=>handleChangeLot(2,value)}/>
                    )}
                </Grid>
            </Grid>*/}
        </React.Fragment>
    )

}


    /*<React.Fragment>
                <td>
                {produit.descriptionCourte}
            </td>
            <td>
                {produit.quantite}
            </td>
            <td>
                <Number name="Quantité" defaultValue={produit.quantite} ornementFin={produit.uniteMesure} decimalScale={4} onChange={handleChangeQuantite}/>
            </td>
            <td>
                <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options0} helperText={helperTexts[0]} onChange={(value)=>handleChangeLot(0,value)}/>
                {(selectedOption[0] && selectedOption[0].quantiteMax*1<quantiteNecessaire*1 && options1.length > 0 &&
                    <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options1} helperText={helperTexts[1]} onChange={(value)=>handleChangeLot(1,value)}/>
                )}
                {(selectedOption[0] && selectedOption[1] && selectedOption[0].quantiteMax*1 + selectedOption[1].quantiteMax*1 <quantiteNecessaire*1 && options2.length > 0 &&
                    <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options2} helperText={helperTexts[2]} onChange={(value)=>handleChangeLot(2,value)}/>
                )}
                </td>
        </React.Fragment>*/