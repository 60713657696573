import React, { useState, useContext, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {Drawer, AppBar, Toolbar, List, CssBaseline, Typography, Divider, IconButton, ListItem, ListItemIcon, ListItemText, CircularProgress, Box } from '@mui/material';
import { Menu, ChevronLeft, MoveToInbox, Mail, Group, PlaylistAdd, Tune, Equalizer, Exposure, FreeBreakfast, CallMerge, DoneAll, Build, Person, Settings } from '@mui/icons-material';
import AutoAwesomeMotion from '@mui/icons-material/AutoAwesomeMotion';
import AccueilAdmin from './components/AccueilAdmin'
import Productions from './components/Productions'
import Fournisseurs from './components/Fournisseurs'
import MvtStock from './components/MvtStock'
import Stock from './components/Stock'
import Recettes from './components/Recettes'
import GroupesRecettes from './components/GroupesRecettes'
import Produits from './components/Produits'
import FabricablePar from './components/FabricablePar'
import Operations from './components/Operations'
import Utilisateurs from './components/Utilisateurs' 
import Parametres from './components/Parametres' 
import './Admin.css'
import {SnackbarProvider} from 'notistack';
import { ThemeContext } from '../../App';
import { UserContext } from "../../services/UserContext"
import API from "../../services/API"

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Admin({loaded}){
    const [theme2,setTheme2] = useContext(ThemeContext)
    const theme = useTheme();
    const [userContext, setUserContext] = useContext(UserContext)
    const [open, setOpen] = useState(false);
    const [typeContenu, setTypeContenu] = useState("defaut")


    useEffect(()=>{
      if(!!userContext.prenom) return
      API("GET","utilisateur/me",{},{},"application/json",{credentials:"include"}, userContext.token)
      .then(res=>{
          if (res.ok) {
              setUserContext(currVal => ({...currVal, ...res.json[0] }))
          } else {
              if (res.status === 401) {
                // Edge case: when the token has expired.
                // This could happen if the refreshToken calls have failed due to network error or
                // User has had the tab open from previous day and tries to click on the Fetch button
                window.location.reload()
              } /*else {
                setUserContext(oldValues => {
                  return { ...oldValues, details: null }
                })
              }*/
            }
      })
  },[userContext])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
      <SnackbarProvider maxSnack={5}>
        {!loaded && 
            <Box sx={{ display: 'flex', justifyContent:"center"}}>
                <CircularProgress size={100} />
            </Box>
        }
        {loaded && 
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <CustomAppBar pposition="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        onClick={handleDrawerOpen}
                        edge="start"
                        csx={{
                          marginRight: 5,
                          ...(open && { display: 'none' }),
                        }}
                    ><Menu /></IconButton>
                    <Typography variant="h6" noWrap>Paramétrages et administration</Typography>
                </Toolbar>
            </CustomAppBar>
            <CustomDrawer variant="permanent" open={open} >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}><ChevronLeft /></IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary="Données dynamiques" />
                    </ListItem>
                {ongletsDynamique.map((item) => (
                    <ListItem button onClick={()=>setTypeContenu(item.texte)} key={item.texte}>
                        <ListItemIcon>{item.icone}</ListItemIcon>
                        <ListItemText secondary={item.texte} />
                    </ListItem>
                ))}
                </List>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary="Données statiques" />
                    </ListItem>
                {ongletsStatique.map((item) => (
                    <ListItem button onClick={()=>setTypeContenu(item.texte)} key={item.texte}>
                        <ListItemIcon>{item.icone}</ListItemIcon>
                        <ListItemText secondary={item.texte} />
                    </ListItem>
                ))}
                </List>
            </CustomDrawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                  <DrawerHeader />
                  <AccueilAdmin visible={typeContenu=="defaut"} />
                  <Productions visible={typeContenu==="Productions"}/>
                  <MvtStock visible={typeContenu=="Mvt stock"} />
                  <Stock visible={typeContenu=="Stock"} />
                  <Fournisseurs visible={typeContenu=="Fournisseurs"} />
                  <Produits visible={typeContenu=="Produits"}/>
                  <Recettes visible={typeContenu=="Recettes"} />
                  <GroupesRecettes visible={typeContenu=="GroupesRecettes"} />
                  <FabricablePar visible={typeContenu=="FabricablePar"} />
                  <Operations visible={typeContenu=="Opérations"} />
                  <Utilisateurs visible={typeContenu=="Utilisateurs"} />
                  <Parametres visible={typeContenu=="Parametres"} />
            </Box>
          </Box>
        }
      </SnackbarProvider>
    );
}

const ongletsDynamique = [
    {texte:"Productions", icone:<DoneAll/>},
    {texte:"Stock", icone:<Equalizer/>},
    {texte:"Mvt stock", icone:<Exposure/>}
]
const ongletsStatique = [
  {texte:"Fournisseurs", icone:<Group/>}, 
  {texte:"Produits", icone:<FreeBreakfast/>},
  {texte:"Recettes", icone:<Tune/>},
  {texte:"GroupesRecettes", icone:<AutoAwesomeMotion/>},
  {texte:"FabricablePar", icone:<CallMerge/>},
  {texte:"Opérations", icone:<Build/>},
  {texte:"Utilisateurs", icone:<Person/>},
  {texte:"Parametres", icone:<Settings/>},
]