import React, {useState, useEffect} from 'react';
//import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
//import DateFnsUtils from '@date-io/date-fns';
import {TextField} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import frLocale from 'date-fns/locale/fr';

export default function DatePickr({name, defaultValue, required, onChange}){ 
    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(false)

    useEffect(()=>{
        setValue(defaultValue)
    },[defaultValue])

    const handleChange=(date)=>{
        setValue(date)
        onChange(date)
        /*if(required && (Object.prototype.toString.call(date) !== "[object Date]" || isNaN(date.getTime()))){
            //setError(true)
        }else{
            setError(false)
        }
        
        if(date !== null){
            onChange(date)
        }*/
    }

    return(
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <DatePicker
                label={name}
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
                error={error}
            />
        </LocalizationProvider>
    )
}