import React, { useEffect, useState } from 'react';
import { Fab, IconButton } from '@mui/material';
import { Add, Edit, Delete, Visibility, VisibilityOff } from '@mui/icons-material';
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import API from '../../../services/API'
import PopupDB from './PopupDB';
import {useSnackbar} from 'notistack';


/*const columns = [
    { field: 'id', title: 'ID'},
    { field: 'nom', title: 'Nom' }
];*/

export default function Utilisateurs({visible}){
    const [popupOpen, setPopupOpen] = useState(false)
    const [utilisateurs, setUtilisateurs] = useState([])
    const [dataChange, setDataChange] = useState(true)
    const [actionPopup, setActionPopup] = useState("Créer")
    const [modifyingData, setModifyingData] = useState({})
    
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {field: 'actions', headerName: 'Actions', sortable: false,
            renderCell: (params) => {
              const onClickModify = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                handleModify(params.row)
              };
              const onClickDelete = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                let conf = window.confirm("Etes-vous sûr de vouloir supprimer cette production ? Pas de retour en arrière ...")
                if(conf){
                    handleDelete(params.row)
                }
              };
        
              return(
                  <React.Fragment>
                      <IconButton color="primary" component="span" onClick={onClickModify}><Edit /></IconButton>
                      <IconButton color="primary" component="span" onClick={onClickDelete}><Delete /></IconButton>
                  </React.Fragment>
              )
            },
        },
        { field: 'id', headerName: 'ID',flex:0.5},
        { field: 'nom', headerName: 'Nom', flex:1 },
        { field: 'actif', headerName: 'Actif', flex:1 },
        { field: 'role', headerName: 'Rôle', flex:1 },
    ];

    useEffect(()=>{
        if(!dataChange) return
        API("GET","utilisateur")
        .then(res => {
            if(!res.ok) return //erreur
            res=res.json
            res.map(r=>r.actif=r.actif?"Oui":"Non")
            setUtilisateurs(res)
        }, err => console.log(err))
        setDataChange(false)
    },[dataChange])

    /*const actions=[{
        icon:()=><Edit/>,
        iconProps:{color:'primary'} ,
        tooltip:'Editer ligne',
        onClick:(event, rowData)=>handleModify(rowData)
    },{
        icon:()=><Delete/>,
        iconProps:{color:'primary'} ,
        tooltip:'Supprimer ligne',
        onClick:(event, rowData)=>handleDelete(rowData)
    }]*/
    const handleAdd = ()=>{
        setActionPopup("Créer")
        setModifyingData({})
        setPopupOpen(true)
    }

    const handleModify = (data)=>{
        setActionPopup("Modifier")
        setModifyingData(data)
        setPopupOpen(true)
    }

    const handleDelete = (data)=>{
        API("DELETE","Utilisateur",{id:data.id})
        .then(res=>{
            if(!res.ok){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            setDataChange(true)
        })
    }

    return(
        (visible &&
            <React.Fragment>
                    <DataGrid  
                        rows={utilisateurs} 
                        columns={columns} 
                        disableSelectionOnClick 
                        disableColumnFilter 
                        autoHeight
                    />
                <Fab color="primary" aria-label="Add" className="fab" onClick={handleAdd}>
                    <Add />
                </Fab>
                <PopupDB action={actionPopup} data={modifyingData} extraData={[]}  table="utilisateur" open={popupOpen} setOpen={setPopupOpen} setDataChange={setDataChange}/>
            </React.Fragment>
        )
    )
}
