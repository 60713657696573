import React, {useState, useEffect} from 'react';
import { Grid, Box, Divider } from '@mui/material';
import API from '../../../services/API'
import Dropdown from '../../../components/Dropdown';
import Number from '../../../components/Number';

export default function BlocIngredientQuantite({ingredient, onChange, quantiteProduction}){
    const [options0, setOptions0] = useState([])
    const [options1, setOptions1] = useState([])
    const [options2, setOptions2] = useState([])
    const [selectedOption, selectOption] = useState([{}])
    const [quantiteNecessaire, setQuantiteNecessaire] = useState(0)
    const [defValNumLot, setDefValNumLot] = useState([])
    const [helperTexts, setHelperTexts] = useState({0:{text:"",type:"default"},1:{text:"",type:"default"},2:{text:"",type:"default"}})
    const [defValQuantite, setDefValQuantite] = useState()
    

    useEffect(()=>{
        API("GET","production", {}, {IdProduit:ingredient.id, onlyPositiveStock:true})
        .then(res => {
            if(!res.ok) return //erreur
            res=res.json
            let d = []
            res.map(x => {
                let tmp = new Date(x.date.substring(0,10))
                tmp = tmp.toLocaleDateString('fr-FR').replace(/\//gi,"-")
                let numLotStr = x.numeroLot===null ? "" : "NumLot#"+x.numeroLot+" "
                d.push({id:x.id, name:numLotStr+"ID#".concat(x.id,"  du ",tmp,"  ",x.stock," restants"), quantiteMax:x.stock})
            })
            setOptions0(d)
            if(d.length===1){
                setDefValNumLot([d[0].id])
                selectOption([{id:d[0].id,quantiteMax:d.filter(x=>x.id==d[0].id)[0].quantiteMax}])
            }else if(d.length===0){
                setHelperTexts(c=>({...c, 0:{text:"Ingrédient indisponible, production impossible",type:"error"}}))
            }
        })
    }, [ingredient])  

    const handleChangeQuantite = (value) =>{
        setQuantiteNecessaire(value)
    }

    const handleChangeLot = (index, value) =>{
        selectOption(currValue=>({...currValue,[index]:{id:value,quantiteMax:options0.filter(x=>x.id==value)[0].quantiteMax}}))
    }
    useEffect(()=>{
        if(Array.isArray(options0) && selectedOption[0]){
            const tmp = options0.filter(o=>o.id!=selectedOption[0].id)
            setOptions1(tmp)
        }
        if(Array.isArray(options1) && selectedOption[1])
            setOptions2(options1.filter(o=>o.id!=selectedOption[1].id))
    },[selectedOption])


    useEffect(()=>{
        setDefValQuantite((quantiteProduction*ingredient.quantite).toFixed(4))
    },[quantiteProduction, ingredient])

    useEffect(()=>{
        verifierQuantites();
    },[quantiteNecessaire,selectedOption])

    function verifierQuantites(){
        if(isNaN(quantiteNecessaire) || quantiteNecessaire <= 0 || !selectedOption[0]){
            onChange(ingredient.id,undefined)
            return
        }
        switch(options0.length){
            case 0:
                break
            case 1:
                if(selectedOption[0].quantiteMax*1<quantiteNecessaire*1){
                    setHelperTexts(c=>({...c, 0:{text:"Quantité en stock insuffisante",type:"error"}}))
                    onChange(ingredient.id,undefined)
                }else{
                    setHelperTexts(c=>({...c, 0:{text:"",type:"default"}}))
                    onChange(ingredient.id,{[selectedOption[0].id]:quantiteNecessaire}, ingredient.id)
                }
                break
            case 2:
                if(selectedOption[0].quantiteMax*1>=quantiteNecessaire*1){
                    setHelperTexts(c=>({...c, 0:{text:"",type:"default"}}))
                    onChange(ingredient.id,{[selectedOption[0].id]:quantiteNecessaire}, ingredient.id)
                }else if(selectedOption[1] == undefined){
                    onChange(ingredient.id,undefined)
                }else if(selectedOption[0].quantiteMax*1+selectedOption[1].quantiteMax*1<quantiteNecessaire*1){
                    setHelperTexts(c=>({...c, 1:{text:"Quantité en stock insuffisante",type:"error"}}))
                    onChange(ingredient.id,undefined)
                }else{
                    setHelperTexts(c=>({...c, 1:{text:"",type:"default"}}))
                    onChange(ingredient.id,{[selectedOption[0].id]:selectedOption[0].quantiteMax, [selectedOption[1].id]:quantiteNecessaire-selectedOption[0].quantiteMax}, ingredient.id)
                }
                break
            default:
                if(selectedOption[0].quantiteMax*1>=quantiteNecessaire*1){
                    setHelperTexts(c=>({...c, 0:{text:"",type:"default"}}))
                    onChange(ingredient.id,{[selectedOption[0].id]:quantiteNecessaire}, ingredient.id)
                }else if(selectedOption[1] == undefined){
                    onChange(ingredient.id,undefined)
                }else if(selectedOption[0].quantiteMax*1+selectedOption[1].quantiteMax*1>=quantiteNecessaire*1){
                    setHelperTexts(c=>({...c, 0:{text:"",type:"default"}}))
                    onChange(ingredient.id,{[selectedOption[0].id]:selectedOption[0].quantiteMax, [selectedOption[1].id]:quantiteNecessaire-selectedOption[0].quantiteMax}, ingredient.id)
                }else if(selectedOption[2] == undefined){
                    onChange(ingredient.id,undefined)
                }else if(selectedOption[0].quantiteMax*1+selectedOption[1].quantiteMax*1+selectedOption[2].quantiteMax*1<quantiteNecessaire*1){
                    setHelperTexts(c=>({...c, 1:{text:"Quantité en stock insuffisante",type:"error"}}))
                    onChange(ingredient.id,undefined)
                }else{
                    setHelperTexts(c=>({...c, 1:{text:"",type:"default"}}))
                    onChange(ingredient.id,{[selectedOption[0].id]:selectedOption[0].quantiteMax, [selectedOption[1].id]:selectedOption[1].quantiteMax, [selectedOption[2].id]:quantiteNecessaire-selectedOption[0].quantiteMax-selectedOption[1].quantiteMax}, ingredient.id)
                }
        }
    }

    return(
        <Box sx={{display: 'grid', gridAutoColumns: '1fr', fontSize:{xs:"14px", md:"1rem"}}}>
            <Box sx={{ gridRow: '1', gridColumn:"span 2", borderRight:"1px solid lightgray", textAlign:"center", padding:"8px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div>{ingredient.descriptionCourte}</div> 
            </Box>
            <Box sx={{ gridRow: '1', gridColumn:"span 2", borderRight:"1px solid lightgray", textAlign:"center", padding:"8px"}}>
                <Number name="Quantité" defaultValue={defValQuantite} ornementFin={ingredient.uniteMesure} decimalScale={4} onChange={handleChangeQuantite}/>
            </Box>
            <Box sx={{ gridRow: '1', gridColumn:"span 2", textAlign:"center", padding:"8px"}}>
                <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options0} helperText={helperTexts[0]} onChange={(value)=>handleChangeLot(0,value)}/>
                {(selectedOption[0] && selectedOption[0].quantiteMax*1<quantiteNecessaire*1 && options1.length > 0 &&
                    <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options1} helperText={helperTexts[1]} onChange={(value)=>handleChangeLot(1,value)}/>
                )}
                {(selectedOption[0] && selectedOption[1] && selectedOption[0].quantiteMax*1 + selectedOption[1].quantiteMax*1 <quantiteNecessaire*1 && options2.length > 0 &&
                    <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options2} helperText={helperTexts[2]} onChange={(value)=>handleChangeLot(2,value)}/>
                )}
            </Box>
        </Box>
        /*<React.Fragment>
            <td>
                {ingredient.descriptionCourte}
            </td>
            <td>
                <Number name="Quantité" defaultValue={defValQuantite} ornementFin={ingredient.uniteMesure} decimalScale={4} onChange={handleChangeQuantite}/>
            </td>
            <td>
                <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options0} helperText={helperTexts[0]} onChange={(value)=>handleChangeLot(0,value)}/>
                {(selectedOption[0] && selectedOption[0].quantiteMax*1<quantiteNecessaire*1 && options1.length > 0 &&
                    <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options1} helperText={helperTexts[1]} onChange={(value)=>handleChangeLot(1,value)}/>
                )}
                {(selectedOption[0] && selectedOption[1] && selectedOption[0].quantiteMax*1 + selectedOption[1].quantiteMax*1 <quantiteNecessaire*1 && options2.length > 0 &&
                    <Dropdown name="Numéro lot" defaultValue={defValNumLot} options={options2} helperText={helperTexts[2]} onChange={(value)=>handleChangeLot(2,value)}/>
                )}
            </td>
        </React.Fragment>*/
    )
}