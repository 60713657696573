import React, { FC, useEffect, useState, forwardRef } from "react";
import { ReactSortable } from "react-sortablejs";
import { Chip, Typography, Box, Paper, Button, IconButton, Fab } from "@mui/material";
import API from "../../../services/API";
import LabelsPicker from "../../../components/LabelsPicker";
import { Add, Edit, Delete, Done, Close, Save } from '@mui/icons-material';
import {useSnackbar} from 'notistack';

const CustomComponent = forwardRef((props, ref) => {
  return (
    <Box ref={ref}
      sx={{
        display:"grid", gridTemplateColumns:"repeat(4, 1fr)"
      }}
    >
      {props.children}
    </Box>
  )
});
/*const CustomComponent = forwardRef((props, ref) => {
  return (
    <div
      sx={{
        display:"grid", gridTemplateColumns:"repeat(4, 1fr)"
      }}
    >
      {props.children}
    </div>
  )
});*/

export default function GroupesRecettes({visible}) {
  const [dataChange, setDataChange] = useState(true)
  const [modifGroupes, setModifGroupes] = useState(false)
  const [groupes, setGroupes] = useState([]);
  const [extendedGroups, setExtendedGroups] = useState([]);
  const [unsavedGroups, setUnsavedGroups] = useState([])
  const [state1, setState1] = useState([]);
  const [state2, setState2] = useState([]);
  const [state3, setState3] = useState([]);
  const [state4, setState4] = useState([]);
  const [state5, setState5] = useState([]);
  const [state6, setState6] = useState([]);
  const [state7, setState7] = useState([]);
  const [state8, setState8] = useState([]);
  const [state9, setState9] = useState([]);
  
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if(!dataChange) return
    setDataChange(false)
    API("GET", "recette/DESCRIBE")
    .then(res => {
      if(!res.ok) return //erreur
      res=res.json
      let exp = /^enum\(\'(.*)\'\)$/
      let tmpGroupes = exp.exec(res.find(x => x.Field == "groupe").Type)[1].split("','").map((v, k) => ({ id: k + 1, Nom: v }))
      if(tmpGroupes.length===1 && tmpGroupes[0].Nom==="") tmpGroupes=[]
      setGroupes(tmpGroupes)
      
      let promises = []
      promises.push(API("GET","recette").then(res=>res.json.map(r=>({...r,famille:"recette"}))))
      promises.push(API("GET","operation").then(res=>res.json.map(r=>({...r,famille:"operation"}))))

      Promise.all(promises).then(allRes=>{
        let tab = [...allRes[0],...allRes[1]]
        
        tmpGroupes.forEach(g=>{
          let r = tab.filter(r=>r.groupe==g.Nom)
          eval("setState"+g.id+"(r)")
        })
        let sansGroupe = tab.filter(r=>r.groupe=="")
        setExtendedGroups([...tmpGroupes,{id:tmpGroupes.length+1, Nom:""}])
        eval("setState"+(tmpGroupes.length+1)+"(sansGroupe)")
      })
    })

      
  }, [dataChange])



  const handleChangeGroupes = (groupes) => {
    setUnsavedGroups(groupes)
  }
  const handleSaveGroupes = () => {
    setModifGroupes(false)
    if(JSON.stringify(groupes) === JSON.stringify(unsavedGroups)) return
    API("PUT", "recette/structure/groupe", {groupe:unsavedGroups.map(g=>g.Nom)})
    API("PUT", "operation/structure/groupe", {groupe:unsavedGroups.map(g=>g.Nom)})
    .then(res=>{
      if(res.ok){
        setDataChange(true)
      }
    })
  }

  const handleSave = ()=>{
    let promises = []
    promises.push(API("PUT", "recette/structure/groupe", {groupe:groupes.map(g=>g.Nom)}))
    promises.push(API("PUT", "operation/structure/groupe", {groupe:groupes.map(g=>g.Nom)}))

    let tmpRecettes = []
    let tmpOperations = []
    groupes.forEach(g=>{
      eval("state"+g.id).forEach((r,key)=>{
        if(r.famille==="recette"){
          tmpRecettes.push({id:r.id, nom:r.nom, verbe:r.verbe, groupe:g.Nom, position:key+1})
        }else if(r.famille==="operation"){
          tmpOperations.push({id:r.id, type:r.type, nom:r.nom, actif:r.actif, groupe:g.Nom, position:key+1})
        }
      })
    })
    promises.push(API("PUT","recette", tmpRecettes))
    promises.push(API("PUT","operation", tmpOperations))

    Promise.all(promises).then(allRes=>{
      let isOk = allRes.reduce((a,v)=>a=a&&v.ok,true)
      if(isOk){
        enqueueSnackbar("Modification réussie",{variant: "success"})
      }else{
        enqueueSnackbar("Erreur lors de la modification des groupes recettes",{variant: "error"})
      }
      setDataChange(true)
    })
  }


  return (
    (visible &&
      <React.Fragment>
        {!modifGroupes &&
          <Button variant="outlined" onClick={() => setModifGroupes(true)}>Modifier les groupes</Button>
        }
        {modifGroupes &&
          <React.Fragment>
            <LabelsPicker name="Groupes" initialLabels={groupes} initialSelectedLabels={groupes} allowCreation={true} onChange={handleChangeGroupes} width={300} />
            <IconButton onClick={handleSaveGroupes}><Done /></IconButton>
            <IconButton onClick={() => setModifGroupes(false)}><Close /></IconButton>
          </React.Fragment>
        }
        
          <ReactSortable list={extendedGroups} setList={setExtendedGroups} tag={CustomComponent}>
            {extendedGroups.map((g) => (
              <Paper key={g.id} sx={{m:1, p:1}}>
                <Typography variant="h6" gutterBottom component="div">
                  {g.Nom}
                </Typography>
                <ReactSortable group="recettes" pull={true} put={true} list={eval("state" + g.id)} setList={eval("setState" + g.id)}>
                  {eval("state" + g.id).map((item) => (
                    <Chip key={item.id} label={item.nom} variant="outlined" sx={{ display: "block" }} />
                  ))}
                </ReactSortable>
              </Paper>
            ))}
          </ReactSortable>
          <Fab color="primary" aria-label="Add" className="fab" onClick={handleSave}><Save/></Fab>
      </React.Fragment>
    )
  );
}
