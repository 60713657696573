import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CheckboxGroup from '../../../../../components/CheckboxGroup';


export default function TypeChecklist({action, onChange}){
    const [options, setOptions] = useState([])

    useEffect(()=>{
        setOptions(action.source.values.map(v=>({id:v[0],name:v[1]})))
    }, [action])

    const handleChange=(states)=>{
        if(action.source.expected === "allChecked"){
            if(Object.values(states).length !== 0 && Object.values(states).reduce((x,y)=>x&&y)){
                onChange(action.id, "allChecked")
            }else{
                onChange(action.id,undefined)
            }
        }else{
            onChange(action.id, states)
        }
    }
    
    return(
        <Container className="Action_Recette">
            <CheckboxGroup name={action.nom} options={options} onChange={handleChange}/>
        </Container>
    )
}