import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Container, Box} from '@mui/material';
import API from '../../../../services/API'
import ActionsOperation from '../Actions/ActionsOperation'
import Dropdown from '../../../../components/Dropdown';
import Number from '../../../../components/Number';
import SearchableDropdown from '../../../../components/SearchableDropdown'
import Toggle from '../../../../components/Toggle'
import {useSnackbar} from 'notistack';
import Input from '../../../../components/Input';
import SupplementEnvoiCde from '../SupplementEnvoiCde'

export default function PopupOperation({operationId, open, setOpen, idUtilisateur} ){
    const [operationComplete, setOperationComplete] = useState({id:operationId});
    
    const [reponsesLogOperation, setReponsesLogOperation] = useState({Utilisateur_id:"",Operation_id:undefined})
    const [reponsesActionsOperation, setReponsesActionsOperation] = useState({})
    const [formValid, setFormValid] = useState(false)
    const [supplementFormValid, setSupplementFormValid] = useState(false)
    const [submiting, setSubmiting] = useState(false)

    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        setReponsesLogOperation(currVal => ({...currVal, Utilisateur_id:idUtilisateur}))
    }, [idUtilisateur])
    
    useEffect(()=>{
        if (operationId === 0) return
        setReponsesLogOperation(currVal => ({...currVal, Operation_id:operationId}))
        API('GET', 'operation/'+operationId+'/complete')
        .then(res => {setOperationComplete(res.json)})
    }, [operationId]) 


    const chgtReponsesActionsOperation = (key, value) => {
        setReponsesActionsOperation(currVal => ({...currVal, [key]:value}))
    }

    useEffect(()=>{
        const answers = [].concat(Object.values(reponsesLogOperation),Object.values(reponsesActionsOperation))
        const answersValid = answers.reduce((x,y)=>x&&(y!==undefined))
        setFormValid(answersValid && (supplementFormValid || operationComplete.type==="defaut"))
    },[reponsesLogOperation, reponsesActionsOperation, supplementFormValid])


    function resetForm(){
        setOpen(false)

        //setReponsesLogOperation(currVal=>({...currVal,Operation_id:undefined}))
        setReponsesActionsOperation({})
        setFormValid(false)
        setSupplementFormValid(false)
        setSubmiting(false)
    }

    function submitForm(){
        API("POST","logOperation",reponsesLogOperation)
        .then(res=>{
            if(!res.ok){  //ERREUR
                enqueueSnackbar("Erreur lors de la création dans la table logOperation",{variant: "error"})
                return
            }
            const LogOperation_id = res.json.insertId
            setSubmiting(LogOperation_id)

            let tmp =[]
            Object.keys(reponsesActionsOperation).map(k=>{
                tmp.push({LogOperation_id:LogOperation_id, ActionOperation_id:k, reponse:reponsesActionsOperation[k]})
            })
            if(tmp.length>0){
                API("POST","reponseOperation",tmp)
                .then(res=>{
                    if(!res.ok){  //ERREUR
                        enqueueSnackbar("Erreur lors de la création dans la table reponseOperation",{variant: "error"})
                        return
                    }
                })
            }

            
            enqueueSnackbar("Opération \""+operationComplete.nom+"\" réussie : #"+LogOperation_id,{variant: "success"})
            resetForm()
        }) 
    }

    return(
        <Dialog open={open} /*onClose={()=>setOpen(false)}*/ aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth='lg'>
            <DialogTitle id="responsive-dialog-title">{operationComplete.nom}</DialogTitle>
            <DialogContent>
                <SupplementTypeOperation operation={operationComplete} setSupplementFormValid={setSupplementFormValid} submiting={submiting}/>
                <ActionsOperation reloadOn={operationId} operationId={operationId} onChange={chgtReponsesActionsOperation}/>
                

            </DialogContent>
            <DialogActions>
                <Button onClick={()=>resetForm()} variant="contained" color="secondary">Annuler</Button>
                <Button disabled={!formValid} onClick={()=>submitForm()} variant="contained" color="primary" autoFocus>Valider</Button>
            </DialogActions>
        </Dialog>
    )
}


function SupplementTypeOperation({operation,setSupplementFormValid,submiting}){
    const typeOperation = {
        undefined:          ()=>{return null},
        "defaut":           ()=>{return null},
        "ajustMvtStock":    (operation,setSupplementFormValid,submiting)=><SupplementAjustMvtStock operation={operation} setSupplementFormValid={setSupplementFormValid} submiting={submiting} />,
        "envoiCde":         (operation,setSupplementFormValid,submiting)=><SupplementEnvoiCde operation={operation} setSupplementFormValid={setSupplementFormValid} submiting={submiting} />,
    }
    return(
        typeOperation[operation.type](operation,setSupplementFormValid,submiting)
    )
}

function SupplementAjustMvtStock({operation,setSupplementFormValid,submiting}){
    const [productions, setProductions] = useState([])
    const [reponseProduction, setReponseProduction] = useState({Production_id:undefined,quantite:undefined,cause:undefined})
    const [uniteMesure, setUniteMesure] = useState("")
    const [causes, setCauses] = useState([])

    const { enqueueSnackbar } = useSnackbar();


    useEffect(()=>{
        API("GET","MouvementStock/DESCRIBE")
        .then(res=>{
            let exp = /^enum\(\'(.*)\'\)$/
            setCauses(exp.exec(res.json.find(x=>x.Field=="cause").Type)[1].split("','").map((v,k)=>({id:v,name:v})))
        })

        API("GET","production")
        .then(res=>setProductions(res.json.map(r=>({id:r.id,Produit_id:r.Produit_id,name:r.id+"  -  "+r.designation+"  -  stock: "+parseFloat(r.stock),uniteMesure:r.uniteMesure}))))
    },[operation])

    const handleChange=(key, value)=>{
        setReponseProduction(currVal => ({...currVal, [key]:value}))
    }

    useEffect(()=>{
        if(reponseProduction.Production_id!==undefined){
            setUniteMesure(productions.find(p=>p.id===reponseProduction.Production_id).uniteMesure)
        }
        setSupplementFormValid(Object.values(reponseProduction).reduce((x,y)=>x&&(y!==undefined)))
    },[reponseProduction])

    useEffect(()=>{
        if(!submiting) return 
        let prod = productions.find(p=>p.id===reponseProduction.Production_id)
        let qt = reponseProduction.sensMvt ? -1*reponseProduction.quantite : reponseProduction.quantite;
        let tmp = {...reponseProduction,ref_LogOperation_id:submiting,Produit_id:prod.Produit_id, quantite:qt}
        API("POST","mouvementStock",tmp)
        .then(res=>{
            if(!res.ok){  //ERREUR
                enqueueSnackbar("Erreur lors de la création dans la table mouvementstock",{variant: "error"})
                return
            }
        })
    },[submiting])

    return(
        /*<Container className="Container_Center width300">
        </Container>*/
        <Box sx={{width:{xs:"100%", md:"50%"}, margin:"auto" , "&>div":{padding:10}}}>
            <Dropdown name="Cause" required={true} defaultValue="" options={causes} onChange={(id)=>handleChange("cause",id)}/>
            <SearchableDropdown name="Production à ajuster" required={true} options={productions} onChange={(id)=>handleChange("Production_id",id)} style={{width:"100%"}}/>
            <Toggle name="Sortie de stock" textOff="Entrée de stock" defaultValue={false} onChange={(value)=>handleChange("sensMvt",value)} />
            <Number name="Quantité" required={true} allowNegative={false} decimalScale={4} ornementFin={uniteMesure} onChange={(value)=>handleChange("quantite",value)}/>
            <Input name="Commentaire" required={false} multiline={true} onChange={(value)=>handleChange("commentaire",value)} style={{width:"100%"}}/>
        </Box>
    )
}