import React, {useEffect, useState} from 'react';
import { InputLabel, Select, FormControl, MenuItem, TextField, Autocomplete } from '@mui/material';



export default function Dropdown({name, defaultValue, required, options, helperText, onChange, style}){
    const [selected, select] = useState(defaultValue)
    const [error, setError] = useState(false)
    const [ht, setHt] = useState("")

    useEffect(()=>{
        select(defaultValue)
    },[defaultValue])

    useEffect(()=>{
        if(!helperText) return
        setHt(helperText.text)
        setError(helperText.type==="error")
    },[helperText])
    
    if(required){
        name=name.concat(" *")
    }

    const handleChange= React.useCallback((ev)=>{
        let v=ev.target.value
        select(v)
        if(required && v ===""){
            setError(true)
            onChange(undefined)
        }else{
            setError(false)
            onChange(v)
        }
    });

    const items = React.useMemo(()=>{
        return options.length && options.map((o)=>(
            <MenuItem key={o.id} value={o.id} disabled={o.disabled}>{o.name}</MenuItem>
        ))
    }, [options])
    
    return(
        <TextField select label={name} value={selected} onChange={handleChange} helperText={ht} error={error} style={{width:"100%",...style}} > 
            <MenuItem key={0} value="" disabled></MenuItem>
            {items}
        </TextField>
    )

    /*return(
        <TextField select label={name} value={selected} onChange={handleChange} helperText={ht} error={error} style={style} > 
            <MenuItem key={0} value="" disabled></MenuItem>
            {options.length && options.map((o)=>(
                <MenuItem key={o.id} value={o.id} disabled={o.disabled}>{o.name}</MenuItem>
            ))};
        </TextField>
    )*/
}