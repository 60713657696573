import React, {useEffect, useState} from "react";
import { TextField, Chip, Box } from "@mui/material";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import DoneIcon from "@mui/icons-material/Done";

const filter = createFilterOptions();

export default function LabelsPicker({name, initialLabels, initialSelectedLabels=[], fixedLabels=[],  onChange, allowCreation, width}) {
    const [value, setValue] = useState([...fixedLabels,...initialSelectedLabels.filter(o=>fixedLabels.map(f=>f.id).indexOf(o.id) === -1)]);
    //const [newLabels, setNewLabels] = useState([]);
    const [labels, setLabels] = useState(initialLabels); 

    useEffect(()=>{
        setLabels(initialLabels)
        //console.log(initialLabels)
    },[initialLabels])
    
    useEffect(()=>{
        setValue(initialSelectedLabels)
        //console.log(initialSelectedLabels)
    },[initialSelectedLabels])


    /*useEffect(()=>{
        setValue(currValue=>[...fixedLabels, ...currValue.filter(option=>fixedLabels.indexOf(option) === -1)])
    },[fixedLabels])*/

    useEffect(()=>{
        onChange(value)
    },[value])
  
    return (
        <Autocomplete
            id="labelsPicker"
            multiple
            value={value}
            onChange={(event, selectedValues, reason) => {
                selectedValues = selectedValues.reduce((a,v)=>{
                    if(!v.hasOwnProperty("Nom")){
                        v=  {Nom: v}
                    }
                    a.push(v)
                    return a
                },[])
                /*selectedValues.map(val=>{
                    
                    //return val
                })*/
                setValue([...fixedLabels, ...selectedValues.filter(option=>fixedLabels.indexOf(option) === -1)]);
                const nL = selectedValues.filter((val) => !val.id);
                setLabels([...initialLabels, ...nL]);
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if(allowCreation && params.inputValue !== "" && !filtered.map((r) => r.Nom).includes(params.inputValue)) { // Suggest the creation of a new value
                    filtered.push({
                        Nom: params.inputValue
                    });
                }
                return filtered;
            }}
            options={[...labels].sort((a, b) => {
                // Display the selected labels first.
                let ai = value.indexOf(a);
                ai = ai === -1 ? value.length + labels.indexOf(a) : ai;
                let bi = value.indexOf(b);
                bi = bi === -1 ? value.length + labels.indexOf(b) : bi;
                return ai - bi;
            })}
            /*getOptionSelected={(option, value)=>{
                return option.id===value.id
            }}*/
            getOptionLabel={(option) => {
                return option.Nom + (!option.id/*creation*/ ? " (Création)" : "");
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
                    style={{
                      visibility: selected ? "visible" : "hidden"
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      color: !option.id/*creation*/ && "blue"
                    }}
                  >
                    {option.Nom}
                    <br />
                    <span style={{ fontSize: 10 }}>
                        {!option.id/*creation*/ ? "Création" : ""}
                    </span>
                  </Box>
                </li>
            )}
            //fullWidth={width=="full"}
            sx={{ width: width }}
            //style={{ width: 300 }}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderInput={(params) => (
                <TextField
                {...params}
                label={name}
                variant="outlined"
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip label={option.Nom} {...getTagProps({ index })} disabled={fixedLabels.indexOf(option) !== -1} />
                ))
            } 
        />
    );
}
