import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Container, Box} from '@mui/material';
import API from '../../../../services/API'
import ActionsRecette from '../Actions/ActionsRecette'
import Dropdown from '../../../../components/Dropdown';
import SaisieIngredients from '../SaisieIngredients';
import Number from '../../../../components/Number';
import {useSnackbar} from 'notistack';

export default function PopupProd({idRecette, open, setOpen, idUtilisateur} ){
    const [recetteComplete, setRecetteComplete] = useState({id:idRecette});
    const [produits, setProduits] = useState([]);
    const [produitSelectionne, selectionneProduit] = useState({});
    const [ingredients, setIngredients] = useState([]);
    const [reponsesProduction, setReponsesProduction] = useState({Utilisateur_id:"",Produit_id:undefined,Recette_id:undefined})
    const [reponsesMvtStock, setReponsesMvtStock] = useState({quantite:undefined})
    const [reponsesIngredients, setReponsesIngredients] = useState({})
    const [reponsesActions, setReponsesActions] = useState({})
    const [capturedNL, setCapturedNL] = useState(undefined)
    const [formNonValide, setFormNonValide] = useState(true)

    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        setReponsesProduction(currVal => ({...currVal, Utilisateur_id:idUtilisateur}))
    }, [idUtilisateur])
    
    useEffect(()=>{
        if (idRecette === 0) return
        //setOpen(true);
        setReponsesProduction(currVal => ({...currVal, Recette_id:idRecette}))
        API('GET', 'recette/'+idRecette+'/complete')
        .then(res => setRecetteComplete(res.json))
    }, [idRecette]) 

    useEffect(()=>{
        if (idRecette === 0) return 
        //console.log(recetteComplete)
        if (!recetteComplete.Produits || recetteComplete.Produits.length === 0){
            setProduits([])
        }else{
            API('GET', 'produit',{}, {ids:recetteComplete.Produits/*, IdRecette:recetteComplete.id*/})
            .then(res => setProduits(res.json), err => console.log(err))
        }
    }, [recetteComplete])


    useEffect(()=>{
        if(!reponsesProduction.Produit_id || reponsesProduction.Produit_id == 0) {
            resetForm();
        }else{
            //debugger
            const [pr] = produits.filter(p => p.id == reponsesProduction.Produit_id)
            selectionneProduit(pr);
            //if (!pr.Ingredients || pr.Ingredients.length === 0) return
            API('GET', 'ingredient/complete',{}, {Produit_id:pr.id, Recette_id:idRecette})
            .then(res => {
                //const resPlusQuantiteIngr = res.map(i=>({...i,quantite:pr.Ingredients.filter(p=>p.id==i.id)[0].quantite}))
                setIngredients(res.json)
            }, err => console.log(err))
        }
        if(produitSelectionne.type==="Produit fini"){
            setReponsesProduction(currVal=>({...currVal,numeroLot:undefined}))
        }
    }, [reponsesProduction.Produit_id])

    function chgtReponsesProduction (value, cle) {
        setReponsesProduction(currVal => ({...currVal, [cle]:value}))
    }

    function chgtReponsesMvtStock (value) {
        setReponsesMvtStock(currVal => ({...currVal, quantite:value}))
    }

    const chgtReponsesIngredients=(ing, values)=>{
        setReponsesIngredients(currVal => ({...currVal, [ing]: values}))
    }

    const chgtReponsesActions = (key, value, isCapturedNL=false) => {
        setReponsesActions(currVal => ({...currVal, [key]:value}))
        if(isCapturedNL){
            setCapturedNL(value)
            setReponsesProduction(currVal=>({...currVal,"numeroLot":value}))
        }
    }
    /*const chgtReponsesActions = (key, value, isCapturedNL=false) => {
        if(isCapturedNL){
            setReponsesActions(currVal => ([...currVal, {ActionRecette_id:key, reponse:value, isCapturedNL:true}]))
        }else{
            setReponsesActions(currVal => ([...currVal, {ActionRecette_id:key, reponse:value}]))
        }
        
    }*/

    
    useEffect(()=>{
        if(process.env.NODE_ENV==="development")console.log(reponsesProduction)
    },[reponsesProduction])
    useEffect(()=>{
        console.log(reponsesIngredients)
    },[reponsesIngredients])
    useEffect(()=>{
        //debugger
        //console.log(reponsesActions)
    },[reponsesActions])

    useEffect(()=>{
        const answers = [].concat(Object.values(reponsesProduction),Object.values(reponsesMvtStock),Object.values(reponsesIngredients),Object.values(reponsesActions))
        //const tmp = !answers.reduce((x,y)=>x&&(y!==undefined))
        //setFormNonValide(tmp)
        setFormNonValide(!answers.reduce((x,y)=>x&&(y!==undefined)))
    },[reponsesProduction, reponsesMvtStock, reponsesIngredients, reponsesActions])

    useEffect(()=>{
        if(Object.values(reponsesIngredients).reduce((x,y)=>x&&(y!==undefined),true)){
            let ing = []
            Object.values(reponsesIngredients).map(r=>Object.keys(r).forEach(u=>ing.push(parseInt(u))))
            //let ing2 = Object.values(reponsesIngredients).map(r=>Object.keys(r).map(u=>parseInt(u)))
            if(ing.length===0) return
            API("GET","production",{},{"Production_id":ing, "withoutNullNumLot":true})
            .then(res=>{
                if(!res.ok) return //erreur
                res=res.json
                if(res.length){
                    setReponsesProduction(currVal=>({...currVal,"numeroLot":res[0].numeroLot}))
                }
            })
        }
        /*if(produitSelectionne.type==="Produit fini"){
            if(Object.values(reponsesIngredients).reduce((x,y)=>x&&(y!==undefined))){
                let ing = []
                Object.values(reponsesIngredients).map(r=>Object.keys(r).forEach(u=>ing.push(parseInt(u))))
                const recursive = (ingredients) =>{
                    API("GET","production/searchCapturedNL",{},{"Production_id":ingredients})
                    .then(res=>{
                        if(!res.ok) return //erreur
                        res=res.json
                        if(res.found){
                            setReponsesProduction(currVal=>({...currVal,numeroLot:res.NL}))
                        }else{
                            if(res.Ing_id){
                                recursive(res.Ing_id)
                            }else{
                                API("GET","production/lastNumLot")
                                .then(res2=>setReponsesProduction(currVal=>({...currVal,numeroLot:parseInt(res2.json.lastNumLot)+1})))
                            }
                        }
                    })
                }
                recursive(ing)
            }
        }*/
    },[reponsesIngredients])

    function resetForm(){
        setOpen(false)

        selectionneProduit({})
        setIngredients([])
        setReponsesProduction(currVal => ({...currVal,Produit_id:undefined}))
        setReponsesProduction(currVal => ({Utilisateur_id:currVal.Utilisateur_id,Recette_id:currVal.Recette_id}))
        setReponsesMvtStock({quantite:undefined})
        setReponsesIngredients({})
        setReponsesActions({})
        setFormNonValide(true)
    }

    function submitForm(){
        API("POST","production",reponsesProduction)
        .then(res=>{
            if(!res.ok){  //ERREUR
                enqueueSnackbar("Erreur lors de la création dans la table production",{variant: "error"})
                return
            }
            let Production_id = res.json.insertId
            API("POST","mouvementstock",{...reponsesMvtStock,Production_id:Production_id, cause:"Production",Produit_id:reponsesProduction.Produit_id})
            .then(res=>{
                if(!res.ok){  //ERREUR
                    enqueueSnackbar("Erreur lors de la création dans la table mouvementstock",{variant: "error"})
                    return
                }
            })
            
            let tmp =[]
            Object.keys(reponsesActions).map(k=>{
                if(Array.isArray(reponsesActions[k]))
                    reponsesActions[k] = JSON.stringify(reponsesActions[k])
                tmp.push({Production_id:Production_id, ActionRecette_id:k, reponse:reponsesActions[k]})
            })
            if(tmp.length>0){
                API("POST","reponse",tmp)
                .then(res=>{
                    if(!res.ok){  //ERREUR
                        enqueueSnackbar("Erreur lors de la création dans la table reponse",{variant: "error"})
                        return
                    }
                })
            }

            debugger
            tmp=[]
            for (const [x, y] of Object.entries(reponsesIngredients)){
                for (const [k,v] of Object.entries(y)){
                    tmp.push({Produit_id:x,Production_id:k, quantite:-1*v, ref_Production_id:Production_id, cause:"Ingrédient"})
                }
            }
            
            if(tmp.length>0){
                API("POST","mouvementstock",tmp)
                .then(res=>{
                    if(!res.ok){  //ERREUR
                        enqueueSnackbar("Erreur lors de la création dans la table mouvementstock des ingrédients",{variant: "error"})
                        return
                    }
                })
            }
            if(produitSelectionne.type==="Produit fini"){
                enqueueSnackbar("Création réussie : lot #"+reponsesProduction.numeroLot,{variant: "success", persist:true})
            }else{
                enqueueSnackbar("Création réussie : production #"+Production_id,{variant: "success", persist:true})
                if(capturedNL){
                    enqueueSnackbar("Numéro de lot capturé : #"+capturedNL,{variant: "success", persist:true})
                }
            }
            resetForm()
        }) 
    }

    return(
        <Dialog open={open} /*onClose={()=>setOpen(false)}*/ aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth='md'>
            <DialogTitle id="responsive-dialog-title">{recetteComplete.nom}</DialogTitle>
            <DialogContent>
                <h3>Produit et quantité à {recetteComplete.verbe}</h3>
                {/*<Container className="Container_Center width150">
                </Container>*/}
                <Box sx={{width:{xs:"50%",md:"30%"}, margin:"auto", padding:10}}><Dropdown name="Produit" defaultValue="" options={produits.map((p)=>({id:p.id,name:p.descriptionCourte}))} required={true} onChange={(value)=>chgtReponsesProduction(value, "Produit_id")}/></Box>
                <Box sx={{width:{xs:"50%",md:"30%"}, margin:"auto", padding:10}}><Number name="Quantité" ornementFin={produitSelectionne.uniteMesure} required={true} allowNegative={false} onChange={(value)=>chgtReponsesMvtStock(value)}/></Box>
                <SaisieIngredients ingredients={ingredients} onChange={chgtReponsesIngredients} quantiteProduction={reponsesMvtStock.quantite}/>
                <ActionsRecette produit={produitSelectionne} idRecette={idRecette} onChange={chgtReponsesActions}/>
                

            </DialogContent>
            <DialogActions>
                <Button onClick={()=>resetForm()} variant="contained" color="secondary">
                    Annuler
                </Button>
                <Button disabled={formNonValide} onClick={()=>submitForm()} variant="contained" color="primary" autoFocus>
                    {recetteComplete.nom}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


