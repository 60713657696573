import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CheckboxItem from '../../../../../components/CheckboxItem';


export default function TypeCheckbox({action, onChange}){
    const [defVal, setDefeVal] = useState(false)

    useEffect(()=>{
        if(action.source){
            setDefeVal(action.source.default==="true")
        }
    }, [action])

    const handleChange=(state)=>{
        onChange(action.id, state)
    }
    
    return(
        <Container className="Action_Recette">
            <CheckboxItem name={action.nom} defaultValue={defVal} onChange={handleChange}/>
        </Container>
    )
}