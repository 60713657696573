import React, {useEffect, useState} from 'react';
import { InputLabel, Select, FormControl, MenuItem, TextField, CircularProgress, Autocomplete } from '@mui/material';



export default function SearchableDropdown({name, defaultValue, required, options, helperText, onChange, disabled, style, loading}){
    const [localOptions, setLocalOptions] = useState([])
    const [selected, select] = useState()
    const [error, setError] = useState(false)
    const [ht, setHt] = useState("")

    useEffect(()=>{
        if(defaultValue===undefined) return
        select(localOptions.filter(o=>o.id===defaultValue)[0])
    },[defaultValue,localOptions])

    /*useEffect(()=>{
        const tmp = localOptions.filter(o=>o.id===defaultValue)[0]
        select(tmp)
    },[localOptions])*/

    useEffect(()=>{
        if(options.length===0) return
        let tmp = options
        tmp.unshift({id:0,name:"",disabled:true})
        setLocalOptions(tmp)
    },[options])

    useEffect(()=>{
        if(!helperText) return
        setHt(helperText.text)
        setError(helperText.type==="error")
    },[helperText])
    
    if(required){
        name=name.concat(" *")
    }

    const handleChange=(event,newValue)=>{
        select(newValue)
        if(newValue === null){
            if(required){
                setError(true)
                onChange(undefined)
            }
        }else{
            setError(false) 
            onChange(newValue.id)
        }
    }

    return(
        <Autocomplete
            style={style}
            loading={loading}
            loadingText="Chargement..."
            options={localOptions}
            disabled={disabled}
            getOptionLabel={(option) => option.name||''}
            getOptionDisabled={(option) => option.disabled}
            getOptionSelected={(option, value) => (option.id === value.id)||(value==='' && option.name==='')}
            value={selected||''}
            onChange={handleChange}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    label={name}  
                    variant="outlined" 
                    helperText={ht} 
                    error={error} 
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          </React.Fragment>
                        ),
                      }}
                />
            }
        />
    )
}

