import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Input from '../../../../../components/Input';

export default function TypeInput({action, onChange, produit}){
    const [defVal, setDefeVal] = useState("")
    useEffect(()=>{
        const tmp = eval(action.source && action.source.default)        // peut appeler la variable 'produit'
        if(tmp){
            onChange(action.id, tmp)
            setDefeVal(tmp)
        }else{
            if(action.reponseObligatoire){
                onChange(action.id,undefined)
            }
        }
    },[produit])

    const handleChange = (value)=>{
        if(value==="" && action.reponseObligatoire){
            onChange(action.id,undefined)
        }else{
            onChange(action.id,value)
        }
    }

    return(
        <Container className="Action_Recette">
            <Input name={action.nom} defaultValue={defVal} required={action.reponseObligatoire===1} onChange={handleChange}/>
        </Container>
    )
}