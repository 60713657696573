import React, { useEffect, useState } from 'react'
import TypeListeDeroulante from './components/TypeListeDeroulante'
import TypeDate from './components/TypeDate'
import TypeInput from './components/TypeInput'
import TypeNombre from './components/TypeNombre'
import TypeChecklist from './components/TypeChecklist'
import TypeCheckbox from './components/TypeCheckbox'
import TypeCaptureNL from './components/TypeCaptureNL'
import API from '../../../../services/API'
import { Container } from '@mui/material'

const actions = {
    "Liste déroulante": (action, onChange, produit)=><TypeListeDeroulante key={action.id} action={action} onChange={onChange} produit={produit}/>,
    "Date":             (action, onChange, produit)=><TypeDate key={action.id} action={action} onChange={onChange} produit={produit}/>,
    "Réponse courte":   (action, onChange, produit)=><TypeInput key={action.id} action={action} onChange={onChange} produit={produit}/>,
    "Nombre":           (action, onChange, produit)=><TypeNombre key={action.id} action={action} onChange={onChange} produit={produit}/>,
    "Checklist":        (action, onChange)=><TypeChecklist key={action.id} action={action} onChange={onChange}/>,
    "Checkbox":         (action, onChange)=><TypeCheckbox key={action.id} action={action} onChange={onChange}/>,
    "Capture Num Lot":  (action, onChange)=><TypeCaptureNL key={action.id} action={action} onChange={onChange}/>,
}

export default function ActionsRecette({produit, idRecette, onChange}){
    const [actionsRecette, setActionsRecette] = useState([]);

    useEffect(() => {
        if(!idRecette) return
        API('GET', 'actionRecette', {}, {Recette_id:idRecette})
        .then(res => {
            if(!res.ok) return //erreur
            res=res.json
            res.map(a => a.source=JSON.parse(a.source));
            setActionsRecette(res);
        }, err => console.log(err))
    },[idRecette]);

    return(
        (actionsRecette.length > 0 && 
        <React.Fragment>
            <h3>Autres informations</h3>
            <Container>
                {actionsRecette.map(a=>(actions[a.typeQuestion](a, onChange, produit)))}
            </Container>
        </React.Fragment>)
        
    )
}

