export default function API (method = 'GET', path='', params='', querys={}, type="application/json", init={}, bearer=""){
    let CONFIG
    if(process.env.NODE_ENV==="development"){
        CONFIG=window.CONFIG_API_DEV
    }else{
        CONFIG=window.CONFIG_API_PROD
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", type);
    myHeaders.append("key", CONFIG.key);
    if(bearer!=""){
        myHeaders.append("Authorization", `Bearer ${bearer}`);
    }
    var myInit = {
        method: method,
        ...init
        //headers: myHeaders
    };
    if(method==="POST" || method==="PATCH" || method==="PUT" || method==="DELETE"){
        if(type ==="multipart/form-data"){
            /*var data = new FormData()
            data.append("image",params.valeur)
            delete params.valeur*/
            /*for (const [key, value] of Object.entries(params)) {
                data.append(key,JSON.stringify(value))
            }*/
            //myInit={...myInit, body: data}
            myInit={...myInit, body: params}
        }else{
            myInit={...myInit, headers: myHeaders, body : JSON.stringify(params)}   
        }
    }else{
        myInit={...myInit, headers: myHeaders}
    }
    const url = new URL(CONFIG.url+"/"+path);
    Object.keys(querys).forEach(key => {
        if(querys[key]){
            if(typeof(querys[key])!="string") querys[key] = JSON.stringify(querys[key])
            url.searchParams.append(key, querys[key])
        }
    })

    /*return fetch(url, myInit)
    .then(response => {
        if(response.status === 200 || response.status === 201){
            return response.json();
        }else if(response.status === 204){
            return []
        }else{
            //console.log("%cErreur "method + " - " + path)
            //return response.text();
            return response.json();
        }
    })
    .catch(err => {
        throw new Error(err)
    });*/   
    let tmpResponse
    return fetch(url, myInit)
    .then(async response => {
        tmpResponse = response
        if(response.status === 204){
            tmpResponse.json = []
        }else{
            tmpResponse.json = await response.json()
        }
        return tmpResponse
    })
    .catch(err => {
        if(err.name === "SyntaxError" && 
            (err.message.includes("nexpected") && err.message.includes("JSON") ||   //U-unexpected JSON (chrome ou firefox)
            err.message.includes("string did not match the expected pattern"))){    // idem (safari)
            return tmpResponse
        }
        throw new Error(err)
    });
}
