import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Number from '../../../../../components/Number';

export default function TypeNombre({action, onChange, produit}){
    const [defVal, setDefeVal] = useState("")
    useEffect(()=>{
        const tmp = eval(action.source.default)         // peut appeler la variable 'produit'
        if(tmp){
            onChange(action.id, tmp)
            setDefeVal(tmp)
        }else{
            if(action.reponseObligatoire){
                onChange(action.id,undefined)
            }
        }
    },[produit])

    const handleChange = (value)=>{
        if(value==="" && action.reponseObligatoire){
            onChange(action.id,undefined)
        }else{
            onChange(action.id,value)
        }
    }

    return(
        <Container className="Action_Recette">
            <Number name={action.nom} defaultValue={defVal} required={!!action.reponseObligatoire} ornementFin={action.source.unit} onChange={handleChange}/>
        </Container>
    )
}