import React, { useState, useEffect } from "react"
import Dropdown from "../../../components/Dropdown"
import MaterialTable from 'material-table'
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import API from '../../../services/API'
import {useSnackbar} from 'notistack';


const types=[
    {id: "Matière première", name: "Matière première"},
    {id: "Produit intermédiaire", name: "Produit intermédiaire"},
    {id: "Produit fini", name: "Produit fini"}
]



export default function Productions({visible}){
    const [productions, setProductions] = useState([])
    const [dataDisplayed, setDataDisplayed] = useState([])
    const [dataChange, setDataChange] = useState(true)
    const [filterModel, setFilterModel] = useState({items: [{ columnField: 'type', operatorValue: 'equals', value: 'Matière première' }]}); 

    const { enqueueSnackbar } = useSnackbar();  

    useEffect(()=>{
        if(!dataChange) return
        API("GET","mouvementstock",{},{withIsNotDeletable:1,onlyCause:"Production"})
        .then(res => setProductions(res.json), err => console.log(err))
        setDataChange(false)
    },[dataChange])

/*
    useEffect(()=>{
        setDataDisplayed(mvts.filter(p=>p.type===typeProduit))
    },[mvts, typeProduit])*/
    
    const handleProductTypeChange = React.useCallback( (value) => setFilterModel({items: [{ columnField: 'type', operatorValue: 'equals', value: value }]}) )

    const columns = [
        {field: 'action', headerName: 'Action', sortable: false,
            renderCell: (params) => {
              const onClick = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                let conf = window.confirm("Etes-vous sûr de vouloir supprimer cette production ? Pas de retour en arrière ...")
                if(conf){
                    handleDelete(params.row.Production_id)
                }
              };
        
              return params.row.isNotDeletable===0 && <IconButton color="primary" aria-label="upload picture" component="span" onClick={onClick}><Delete /></IconButton>;
            },
        },
        { field: 'Production_id', headerName: 'Id', flex:1},
        { field: 'type', hide: true },
        { field: 'designation', headerName: 'Nom', flex:1},
        { field: 'date', headerName: 'Date', flex:1},
    ];

    /*const actions=[
        rowData => ({
            icon:()=><Delete/>,
            iconProps:{color:'primary'} ,
            tooltip:'Supprimer cette production',
            onClick:(event, rowData)=>{
                let conf = window.confirm("Etes-vous sûr de vouloir supprimer cette production ? Pas de retour en arrière ...")
                if(conf){
                    handleDelete(rowData.Production_id)
                }
            },
            hidden : rowData.isNotDeletable!==0
        })
    ]*/

    const handleDelete = (id)=>{
        let promises = []
        let success = true
        let tmp = 0
        promises.push(API("DELETE","Production",{id:id})
        .then(res=>res.ok))

        let production = productions.find(x=>x.Production_id==id)
        promises.push(API("DELETE","MouvementStock",{reference_id:id,Produit_id:production.Produit_id, quantite:(-1)*production.quantite})
        //todo : au lieu de supprimer le mvt, en ajouter un négatif, pour avoir un log de tout ?
        .then(res=>res.ok))

        Promise.all(promises).then(v=>{
            if(v.reduce((acc,x)=>acc&&x)){ //si tous les return font vrais
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }else{
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }
            setDataChange(true)
        })
    }

    return(
        (visible &&
            <React.Fragment>
                <Dropdown name="Type produit" defaultValue="Matière première" options={types} onChange={handleProductTypeChange} />
                {/*<MaterialTable data={productions} columns={columns} options={{search: false, showTitle:false, paging:false}} actions={actions}/>*/}
                <div>
                    <DataGrid  
                        rows={productions} 
                        columns={columns} 
                        filterModel={filterModel} 
                        disableSelectionOnClick 
                        disableColumnFilter 
                        autoHeight
                    />
                </div>
            </React.Fragment>
        )
    )
}