import React, {useEffect, useState} from 'react';
import { Switch, Stack, Typography } from '@mui/material';



export default function Toggle({name, textOff, defaultValue, onChange}){
    const [checked, setChecked] = useState(defaultValue)

    /*useEffect(()=>{
        select(defaultValue)
    },[defaultValue])*/

    const handleChange= React.useCallback((ev)=>{
        setChecked(ev.target.checked)
        onChange(ev.target.checked)
    });
    
    return(
        <Stack direction="row" spacing={1} style={{alignItems:"center", justifyContent:"center"}}>
            {textOff != "" && <Typography>{textOff}</Typography>}
            <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>{name}</Typography>
        </Stack>
    )
}