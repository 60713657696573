import React, { useState, useEffect } from "react"
import Dropdown from "../../../components/Dropdown"
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import API from '../../../services/API'


const types=[
    {id: "Matière première", name: "Matière première"},
    {id: "Produit intermédiaire", name: "Produit intermédiaire"},
    {id: "Produit fini", name: "Produit fini"}
]

/*const columns = [
    { field: 'designation', title: 'Nom', width:300},
    { field: 'type', hidden: true },
    { field: 'id', title: 'Numéro de lot'},
    { field: 'quantite', title: 'Stock actuel'},
];*/
const columns = [
    { field: 'designation', headerName: 'Nom', width:300},
    { field: 'type', hide: true },
    { field: 'id', headerName: 'Numéro de lot'},
    { field: 'quantite', headerName: 'Stock actuel'},
];

export default function MvtStock({visible}){
    const [mvts, setMvts] = useState([])
    const [typeProduit, setTypeProduit] = useState("Matière première")
    const [dataDisplayed, setDataDisplayed] = useState([])
    const [dataChange, setDataChange] = useState(true)
    const [filterModel, setFilterModel] = useState({items: [{ columnField: 'type', operatorValue: 'equals', value: 'Matière première' }]}); 

    useEffect(()=>{
        if(!dataChange) return
        API("GET","mouvementstock")
        .then(res => setMvts(res.json), err => console.log(err))
        setDataChange(false)
    },[dataChange])


    const handleProductTypeChange = React.useCallback( (value) => setFilterModel({items: [{ columnField: 'type', operatorValue: 'equals', value: value }]}) )

    return(
        (visible &&
            <React.Fragment>
                <Dropdown name="Type produit" defaultValue={typeProduit} options={types} onChange={handleProductTypeChange} />
                <div>
                    <DataGrid  
                        rows={mvts} 
                        columns={columns} 
                        filterModel={filterModel} 
                        disableSelectionOnClick 
                        disableColumnFilter 
                        autoHeight
                    />
                </div>
                {/*<MaterialTable data={dataDisplayed} columns={columns} options={{search: false, showTitle:false, paging:false}} />*/}
            </React.Fragment>
        )
    )
}