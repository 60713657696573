import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dropdown from '../../../../../components/Dropdown';
import API from '../../../../../services/API'

export default function TypeListeDeroulante({action, produit, onChange}){
    const [options, setOptions] = useState([])
    const [defaultValue, setDefaultValue] = useState("")

    useEffect(()=>{
        debugger 
        console.log(produit)
    },[produit])

    useEffect(()=>{
        if(action.source.type == "API"){
            API(action.source.options.method, action.source.options.path)
            .then(res => {
                if(!res.ok) return //erreur
                res=res.json
                let d = []
                res.map(x => (d.push({id:eval("x."+action.source.options.key), name:eval("x."+action.source.options.name)})))
                setOptions(d)
            })
            const tmp = eval(action.source.default)     // peut appeler variable 'produit'
            if(tmp){
                setDefaultValue(tmp.toString(10))
                onChange(action.id, tmp.toString(10))
            }else{
                onChange(action.id, undefined)
            }
            
        }else if(action.source.type == "ENUM"){
            let d = action.source.options.map(o=>({id:o, name:o}))
            setOptions(d)
        }
    }, [action, produit])
    
    return(
        <Container className="Action_Recette">
            <Dropdown key={action.id} name={action.nom} defaultValue={defaultValue} required={action.reponseObligatoire} options={options} onChange={(value)=>onChange(action.id, value)}/>
        </Container>
    )   
}