import React, { useState, useEffect } from "react"
import {Add, Edit, Delete} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import API from '../../../services/API'
import { Fab, Button, IconButton } from '@mui/material';
import PopupDB from './PopupDB';
import {useSnackbar} from 'notistack';
import Dropdown from "../../../components/Dropdown";

export default function FabricablePar({visible}){
    const [fabricablePar, setFabricablePar] = useState([])
    const [recettes, setRecettes] = useState([])
    const [defValRecette, setDefValRecette] = useState("")
    const [recetteSelectionnee, setRecetteSelectionnee] = useState("")
    const [dataDisplayed, setDataDisplayed] = useState([])
    const [popupOpen, setPopupOpen] = useState(false)
    const [dataChange, setDataChange] = useState(true)
    const [actionPopup, setActionPopup] = useState("Créer")
    const [modifyingData, setModifyingData] = useState({})
    const [filterModel, setFilterModel] = useState({items:[]}); 
    
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        if(!visible) return
        API("GET","Recette")
        .then(res=>{
            if(!res.ok) return //erreur
            res=res.json
            setRecettes(res.map(r=>({id:r.id,name:r.nom})))
            setFilterModel({items: [{ columnField: 'Recette', operatorValue: 'equals', value: res[0].nom }]})
            setDefValRecette(res[0].id)
        })
    },[visible])

    /*useEffect(()=>{
        if(recetteSelectionnee===undefined || recetteSelectionnee===""){
            setDataDisplayed(fabricablePar)
        }else{
            let tmp = fabricablePar.filter(f=>f.Recette_id===recetteSelectionnee)
            setDataDisplayed(tmp)
        }
    },[recetteSelectionnee, fabricablePar])*/

    const columns = [
        {field: 'actions', headerName: 'Actions', sortable: false,
            renderCell: (params) => {
              const onClickModify = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                handleModify(params.row)
              };
              const onClickDelete = (e) => {
                e.stopPropagation(); // don't select this row after clicking
                let conf = window.confirm("Etes-vous sûr de vouloir supprimer cette production ? Pas de retour en arrière ...")
                if(conf){
                    handleDelete(params.row)
                }
              };
        
              return(
                  <React.Fragment>
                      <IconButton color="primary" component="span" onClick={onClickModify}><Edit /></IconButton>
                      <IconButton color="primary" component="span" onClick={onClickDelete}><Delete /></IconButton>
                  </React.Fragment>
              )
            },
        },
        {field: 'id', hide:true},
        {field: 'Recette_id', hide:true},
        {field: 'Recette', headerName: 'Recette', flex:1},
        {field: 'Produit_id', hide:true},
        {field: 'Produit', headerName: 'Produit', flex:1},
        {field: 'NbIngredients', headerName: 'Nb Ingrédients', flex:0.5}
    ];

    /*const actions=[{
        icon:()=><Edit/>,
        iconProps:{color:'primary'} ,
        tooltip:'Editer ligne',
        onClick:(event, rowData)=>handleModify(rowData)
    },{
        icon:()=><Delete/>,
        iconProps:{color:'primary'} ,
        tooltip:'Supprimer ligne',
        onClick:(event, rowData)=>handleDelete(rowData)
    }]*/

    useEffect(()=>{
        if(!dataChange) return
        API("GET","PFabricableParR/custom")
        .then(res => setFabricablePar(res.json))
        setDataChange(false)
    },[dataChange])

    const handleRecetteChange = React.useCallback( (value) => setFilterModel({items: [{ columnField: 'Recette_id', operatorValue: 'equals', value: value }]}) )


    const handleAdd = ()=>{
        setActionPopup("Créer")
        setModifyingData({})
        setPopupOpen(true)
    }

    const handleModify = (data)=>{
        setActionPopup("Modifier")
        setModifyingData(data)
        setPopupOpen(true)
    }

    const handleDelete = (data)=>{
        API("DELETE","PFabricableParR",{id:data.id})
        .then(res=>{
            if(!res.ok){//ERREUR
                enqueueSnackbar("Erreur lors de la suppression",{variant: "error"})
            }else{
                enqueueSnackbar("Suppression réussie",{variant: "success"})
            }
            setDataChange(true)
        })
    }


    return(
        (visible &&
            <React.Fragment>
                <Dropdown name="Recette" defaultValue={defValRecette} options={recettes} onChange={handleRecetteChange} />

                {/*<MaterialTable data={dataDisplayed} columns={columns} options={{search: false, showTitle:false, paging:false}} actions={actions}/>*/}
                <div>
                    <DataGrid  
                        rows={fabricablePar} 
                        columns={columns} 
                        filterModel={filterModel}
                        disableSelectionOnClick 
                        disableColumnFilter 
                        autoHeight
                    />
                </div>
                <Fab color="primary" aria-label="Add" className="fab" onClick={handleAdd}>
                    <Add />
                </Fab>
                <PopupDB action={actionPopup} data={modifyingData} table="PFabricableParR" extraTable="Ingredient" extraData={[]} extraTableMultipleAnswers={true} open={popupOpen} setOpen={setPopupOpen} setDataChange={setDataChange} />
            </React.Fragment>
        )
    )
}