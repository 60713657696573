import { Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import DatePickr from '../../../../../components/DatePickr';


export default function TypeDate({action, onChange, produit}){
    const [selectedDate, selectDate] = useState();

    useEffect(()=>{
        let defaultValue = new Date()
        if(action.source.default === "today"){
            defaultValue = new Date()
        }else if(action.source.default.startsWith("+")){
            let tmp = action.source.default.substring(1)        // peut appeler la variable 'produit'
            if(isNaN(tmp)){
                try{
                    tmp = eval(tmp) || 0
                }catch{
                    tmp = 0
                }
            }
            defaultValue = new Date()
            defaultValue.setMonth(defaultValue.getMonth()+parseInt(tmp))
        }
        onChange(action.id,defaultValue.toJSON().slice(0,10))
        selectDate(defaultValue)
    },[produit])
    


    const handleChange = (date) => {
        selectDate(date);
        if(!isValidDate(date) && action.reponseObligatoire){
            onChange(action.id,undefined)
        }else{
        //if(date.toJSON()){
            onChange(action.id,date.toJSON().slice(0,10))
        }
    }

    return(
        <Container className="Action_Recette">
            <DatePickr key={action.id} name={action.nom} defaultValue={selectedDate} required={action.reponseObligatoire} onChange={handleChange}/>
        </Container>
    )
}

function isValidDate(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
            return false
        } else {
            return true
        }
    } else {
        return false
    }
}