import Sellsy from 'node-sellsy'

var sellsy = new Sellsy({
    creds: {
        consumerKey: window.CONFIG_API_SELLSY.consumerKey,
        consumerSecret: window.CONFIG_API_SELLSY.consumerSecret,
        userToken: window.CONFIG_API_SELLSY.userToken,
        userSecret: window.CONFIG_API_SELLSY.userSecret
    },
    endPoint:window.CONFIG_API_SELLSY.endpoint
});

export function SellsyAPI(method,params){ 
    return sellsy.api({method:method, params:params})
}

export async function SellsyCreateDocument(doctype, parentDoc, rows, keepOtherRows, step, deliveryStep, nbParcels){
    let rateCategories = await accountdatas_getRateCategories()
    parentDoc.hasTaxesInc = rateCategories.response[parentDoc.rateCategory].hasTaxesInc=="Y"
    let shippings = await accountdatas_getShippingList()
    parentDoc.shippings = Object.values(shippings.response)
    let doc = constructDocumentFromAnother(doctype,parentDoc, rows, keepOtherRows, nbParcels)
    createDocument(doc, doctype, step, deliveryStep)
}
export function SellsyDocumentUpdateStep(docid, doctype, step){
    return documentUpdateStep(docid, doctype, step)
}
export function SellsyDocumentUpdateDeliveryStep(docid, step){
    documentUpdateDeliveryStep(docid, step)
}
export async function SellsyDocumentUpdateRows(doc,rows, keepOtherRows){
    let docStep = doc.step
    let rateCategories = await accountdatas_getRateCategories()
    doc.hasTaxesInc = rateCategories.response[doc.rateCategory].hasTaxesInc=="Y"
    let shippings = await accountdatas_getShippingList()
    doc.shippings = Object.values(shippings.response)
    await documentUpdateStep(doc.docid, "order", "draft")
    let query = {
        document : {
            doctype :   doc.linkedtype,
            thirdid :   doc.thirdid
        },
        docid:    doc.id
    }
    query.row = await constructRows(doc, rows, keepOtherRows, true)
    let toReturn = SellsyAPI("document.update",query)
    documentUpdateStep(doc.docid, "order", docStep)
    return toReturn
}

function constructDocumentFromAnother(type, docParent, rows, keepOtherRows, nbParcels){
    let query1 = {
        'document':{
            'doctype':              type,
            'parentId':             docParent.id,
            'thirdid':              docParent.thirdid,
            'thirdident':           docParent.thirdident,
            /*'ident' :               docParent.ident,*/
            'displayedDate' :       Math.trunc(Date.now()/1000),
            'subject' :             docParent.subject,
            'notes' :               docParent.notes,
            'tags' :                docParent.tags.map(x=>x.word).join(),
            'displayShipAddress' :  'Y',
            'rateCategory' :        docParent.rateCategory,
            'globalDiscount' :      docParent.globalDiscount,
            'globalDiscountUnit' :  docParent.globalDiscountUnit,
            //'hasDoubleVat' :        docParent.hasDoubleVat,
            //'hasTvaLawText' :       docParent.hasTvaLawText,
            'currency' :            docParent.currency,
            'doclayout' :           docParent.doclayout,
            //'doclang' :             docParent.doclang,
            'payMediums' :          "" && Object.keys(docParent.payMediums),
            //'docspeakerStaffId'	:   docParent.docspeakerStaffId,
            'useServiceDates' :     'N',
            //'serviceDateStart' :    docParent.serviceDateStart,
            //'serviceDateStop' :     docParent.serviceDateStop,
            'showContactOnPdf' :    docParent.showContactOnPdf,
            'showParentOnPdf' :     'Y',
            //'conditionDocShow' :    docParent.conditionDocShow,
            'corpAddressId' :       docParent.corpAddressId,
            //'enabledPaymentGateways' : docParent.enabledPaymentGateways,
            //'directDebitPaymentGateway': docParent.directDebitPaymentGateway,
        },
        'thirdaddress' : {
            'id' :              docParent.thirdaddressid
        },
        'shipaddress' : {
            'id' :              docParent.shipaddressid
        },
        'row' : []
    }

    if(type=="delivery"){
        query1.shipping={
            'nbParcels' :       nbParcels,
            //'weight'        => {{shipping_weight}},
            //'weightUnit'    => {{shipping_weightUnit}},
            //'volume'        => {{shipping_volume}},
            //'trackingNumber' => {{shipping_trackingNumber}},
            //'trackingUrl'   => {{shipping_trackingUrl}},
            //'date'          => {{shipping_date}}
        }
    }

    if(docParent.paydate && docParent.paydate!=""){
        query1.paydate = {
            'id':       docParent.paydate,
            'xdays' :   docParent.paydate_xdays,
            'endmonth': docParent.paydate_endmonth
        }
    }

    query1.row = constructRows(docParent, rows, keepOtherRows)
    return query1
}
function constructRows(docParent, rows, keepOtherRows, withRowId){
    let toReturn = []
    Object.entries(docParent.map.rows).forEach(([idLigne, rowParent]) => {
    //for (const rowParent of Object.entries(docParent.map.rows)) {
        if(rowParent.type==undefined) return //ligne non valide
        if(rowParent.type!="item" && !keepOtherRows) return

        let quantite
        if(rowParent.type=="item"){
            quantite = rows[rowParent.id]
            if(quantite==undefined || quantite<=0) return //si la quantité de la ligne produit <= 0
        }else{
            quantite = rowParent.qt
        }
        toReturn.push({
            row_type :        rowParent.type,
            row_linkedid :    rowParent.linkedid,
            row_declid :      rowParent.declid,
            row_name :        rowParent.name,
            row_notes :       rowParent.notes,
            row_unit :        rowParent.unitText,
            row_unitAmount :  rowParent.unitAmountTaxesInc,
            row_taxid :       rowParent.taxid,
            row_tax2id :      rowParent.tax2id,
            row_qt :          quantite,
            row_isOption :    rowParent.isOption,
            row_purchaseAmount :   rowParent.purchaseAmount,
            row_title :         rowParent.name,
            //'row_whid' :        rowParent.whid,
            //'row_serial' :           rowParent.serial,
            //'row_barcode' :          rowParent.barcode,
            //'row_accountingCode' :   rowParent.accountingCode
        })
        if(rowParent.type=="shipping"){
            toReturn[toReturn.length-1].row_shipping = docParent.shippings.find(x=>x.id==rowParent.unitid).name
        }
        if(withRowId)
            toReturn[toReturn.length-1].row_id = rowParent.id
        if(rowParent.discount!=0){
            toReturn[toReturn.length-1].row_discount = rowParent.discount;
            toReturn[toReturn.length-1].row_discountUnit = rowParent.discountUnit;
        }
        if(docParent.hasTaxesInc){
            toReturn[toReturn.length-1].row_unitAmount = rowParent.unitAmountTaxesInc
        }else{
            toReturn[toReturn.length-1].row_unitAmount = rowParent.unitAmount
        }
    })
    return toReturn
}

async function createDocument(document, doctype, step, deliveryStep){
    let res1 = await SellsyAPI("Document.create",document);
    documentUpdateStep(res1.response.doc_id, doctype, step)
    documentUpdateDeliveryStep(res1.response.doc_id, deliveryStep)
}

function documentUpdateStep(docid, doctype, step){
    if(["draft","sent","read","accepted","expired","advanced","partialinvoiced","invoiced","cancelled"].includes(step)){
        return SellsyAPI("Document.updateStep",
        {
            docid:      docid,
            document:{
                doctype:    doctype,
                step:       step
            }
        }
        )
    }
}
function documentUpdateDeliveryStep(docid, step){
    if(["none","wait","picking","partialsent","sent"].includes(step)){
        return SellsyAPI("Document.updateDeliveryStep",
            {
                docid:      docid,
                document:{
                    step:       step
                }
            }
        )
    }
}

function accountdatas_getRateCategories(){
    return SellsyAPI("Accountdatas.getRateCategories",{})
}
function accountdatas_getShipping(id){
    return SellsyAPI("Accountdatas.getShipping",{id:id})
}
function accountdatas_getShippingList(){
    return SellsyAPI("Accountdatas.getShippingList",{})
}