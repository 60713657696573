import React, { useEffect } from 'react';
import { FormLabel, FormGroup, FormControl } from '@mui/material';
import CheckboxItem from './CheckboxItem';



export default function CheckboxGroup({name, options, onChange}){
    const [states, setStates] = React.useState([])

    useEffect(()=>{
        /*let s ={}
        options.map(o=>s={...s,[o.id]:false})
        setStates(s)
        onChange(s)*/
        setStates([])
        onChange([])

    },[options])

    const handleChange=(value,name)=>{
        setStates(currVal=>{
            if(value){
                return [ ...currVal, name]
            }else{
                let index = currVal.indexOf(name)
                if (index !== -1) {
                    currVal.splice(index, 1)
                }
                return [...currVal]
            }
        })
    }

    useEffect(()=>{
        onChange(states)
    },[states])

    return(
        <FormControl component="fieldset" /*className={classes.formControl}*/>
            <FormLabel component="legend">{name}</FormLabel>
            <FormGroup>
                {options.map(o=>(
                    <CheckboxItem key={o.id} name={o.name} defaultValue={false} onChange={(value)=>handleChange(value,o.name)}/>
                ))}
            </FormGroup>
        </FormControl>
    )
}