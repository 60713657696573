import React, {useState, useEffect} from 'react';
import Input from './Input';
import { TextField, InputAdornment } from '@mui/material';
import NumberFormat from 'react-number-format';


export default function Number({name, defaultValue, required, allowNegative, decimalScale, minimum, maximum, ornementFin, onChange, disabled, style={fontSize:"1rem", paddingAdornment:"14px"}}){
    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(false)

    const inputProps = {
        endAdornment: <InputAdornment position="end"><span style={{fontSize:style.fontSize}}>{ornementFin}</span></InputAdornment>
    }

    useEffect(()=>{
        setValue(defaultValue)
        onChange(defaultValue)
    },[defaultValue])

    const handleChange=(event)=>{
        let v = event.target.value
        /*let maximumReached = false
        if(!isNaN(maximum)){
            if(v*1>maximum*1){
                v=maximum
                maximumReached = true
            }
        }*/

        setValue(v)
        if(v ===""){
            onChange(undefined)
            if(required){
                setError(true)
            }else{
                setError(false)
            }
        }else{
            setError(false)
            //onChange(v, maximumReached)
            onChange(v*1)
        }
    } 


    return(
        <NumberFormat
            allowNegative={allowNegative}
            customInput={TextField}
            decimalScale={decimalScale} 
            disabled={disabled}
            style={{width:"100%"}}

            label={name}
            value={value}
            required={required}
            error={error}
            variant="outlined"
            InputProps={{...inputProps, style:{fontSize:style.fontSize, paddingRight:style.paddingAdornment}}}
            onChange={handleChange}
            isAllowed={(values) => {
                const {floatValue} = values;
                if(maximum && minimum){
                    return floatValue >= minimum &&  floatValue <= maximum
                }else if(maximum){
                    return floatValue <= maximum
                }else if(minimum){
                    return floatValue >= minimum
                }else{
                    return true
                }
            }}
        />
    )
}
