import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import API from '../../../../../services/API'
import CheckboxItem from '../../../../../components/CheckboxItem';


export default function TypeCaptureNL({action, onChange}){
    const [state, setState] = useState(false)
    const [lastNumLot, setLastNumLot] = useState()

    useEffect(()=>{
        API("GET","production/lastNumLot")
        .then(res=>{
            if(!res.ok) return //erreur
            res=res.json
            setLastNumLot(parseInt(res.lastNumLot)+1)
        })
    },[])

    useEffect(()=>{
        if(state){
            onChange(action.id, lastNumLot, true)
        }else{
            onChange(action.id, "")
        }
    },[lastNumLot,state])

    /*const handleChange=(s)=>{
        if(state){
            onChange(action.id, lastNumLot)
        }else{
            onChange(action.id, "")
        }
        
    }*/
    
    return(
        <Container className="Action_Recette">
            <CheckboxItem name={action.nom} defaultValue={action.source && action.source.default==="true"} onChange={setState}/>
        </Container>
    )
}