import React, { useEffect, useState, useContext, createContext, useCallback } from 'react';
import {BrowserRouter as Router, Navigate, Redirect, Route, Routes, useLocation} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './index.css';
import PagePrincipale from './scenes/PagePrincipale/PagePrincipale'
import Admin from './scenes/Admin/Admin'
import Login from './scenes/Login'
import Pdf from './scenes/Pdf/Pdf'
import API from './services/API';
import WebFont from 'webfontloader';
import { UserContext, UserProvider } from "./services/UserContext"
import { Box, CircularProgress } from '@mui/material';


/*const theme = createTheme({
    palette: {
      primary: {
        main: "#707160",
      },
    },
  });*/


export default function App({}){
    return (
        <ThemeProvide>
            <AppChild/>
        </ThemeProvide>
    );
}
function AppChild({}){
    const [theme,setTheme] = useContext(ThemeContext)
    const [loaded, setLoaded] = useState(false)
    useEffect(()=>{
        /*setTheme({
            primary: "#FF0000",
            secondary: "#ADEFD1FF",
            type: "light",
            background: "#fafafa",
            paper: "#ffffff",
        })*/
        API("GET","parametre")
        .then(res=>{
            if(!res.ok) return //erreur
            res=res.json
            setTheme({
                primary: res.find(r=>r.nom==="colorPrimary").valeur,
                secondary: res.find(r=>r.nom==="colorSecondary").valeur,
                //type: res.find(r=>r.nom==="themeType").valeur,
                background: res.find(r=>r.nom==="colorBackground").valeur,
                paper: res.find(r=>r.nom==="colorPaper").valeur,
                logo: res.find(r=>r.nom==="Logo").valeur, 
                font: res.find(r=>r.nom==="Police").valeur
            })
            setLoaded(true)

            WebFont.load({
                google: {
                    families: [res.find(r=>r.nom==="Police").valeur] 
                }
            })
        })

        
    },[])
    return(
        <UserProvider>
            <div style={{backgroundColor:theme.background, minHeight:"100vh", fontFamily:theme.font}}>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <div>
                    <Router>
                        <Routes>
                            {/*
                                    Taken from react-router docs:
                                    The Route component is perhaps the most important component in React Router to
                                    understand and learn to use well. Its most basic responsibility is to render some
                                    UI when a location matches the route’s path.
                                    https://reacttraining.com/react-router/web/api/Route
                                */}
                            <Route exact path="/" element={<RequireAuth><PagePrincipale loaded={loaded}/></RequireAuth>}/>
                            <Route path="admin" element={<RequireAuth><Admin loaded={loaded}/></RequireAuth>}/>
                            <Route path="login" element={<Login/>}/>
                            {/*<Route path="/pdf" component={Pdf}/>*/}

                            {/* 404 route, default if none of the above routes are satisfied */}
                            {/*<Route path="/404" component={NotFound}/>
                            <Redirect to="/404"/>*/}
                        </Routes>
                    </Router>
                </div>
            </div>
        </UserProvider>
    )
}


//ci-dessous voir post https://stackoverflow.com/questions/68479010/change-application-theme-at-runtime-after-making-an-api-call-from-an-onclick-eve
const ThemeContext = createContext({});

const ThemeProvide = (props) => {
    const [theme, setTheme] = useState({
        primary: "#003e7e",
        secondary: "#ADEFD1FF",
        type: "light",
        background: "#fafafa",
        paper: "#ffffff",
        font: "Roboto"
    });

    const themePalette = createTheme({
        palette: {
            primary: {
                main: theme.primary,
            },
            secondary: {
                main: theme.secondary,
            },
            background: {
                default: theme.background,
                paper: theme.paper,
            },
            type: theme.type,
        },
        typography: {
            fontFamily: theme.font
        }
    });
   
    return (
    <ThemeContext.Provider value={[theme, setTheme]}>
        <ThemeProvider theme={themePalette}>
            {/*<CssBaseline />*/}
            {props.children}
        </ThemeProvider>
    </ThemeContext.Provider>
    );
};
export { ThemeContext, ThemeProvide };

function RequireAuth({ children }) {
    const [userContext, setUserContext] = useContext(UserContext)
    let location = useLocation();
    const isLocalhost = window.location.href.includes("localhost")

    const verifyUser = useCallback(() => {
        if(!isLocalhost){
            API("POST","utilisateur/refreshToken",{},{},"application/json",{credentials:"include"})
            .then(res=>{
                if (res.ok) {
                    setUserContext(currVal => ({ ...currVal, token: res.json.token }))
                } else {
                    setUserContext(currVal => ({ ...currVal, token: null }))
                }
                // call refreshToken every 5 minutes to renew the authentication token.
                setTimeout(verifyUser, 5 * 60 * 1000)
            })
        }
    }, [setUserContext])
    
    useEffect(() => {
        verifyUser()
    }, [verifyUser])


    /** Sync logout across tabs **/
    const syncLogout = useCallback(event => {
        if (event.key === "logout") {
            // If using react-router-dom, you may call history.push("/")
            window.location.reload()
        }
    }, [])
    useEffect(() => {
        window.addEventListener("storage", syncLogout)
        return () => {
            window.removeEventListener("storage", syncLogout)
        }
    }, [syncLogout])




  
    if(userContext.token){
        return children;
    }else if (userContext.token===null || isLocalhost) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }else{
        return(
            <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{display:"block", mx:"auto", my:4}} />
            </Box>
        )
    }
}
